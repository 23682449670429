import React, { useState, useEffect } from "react";
import { Card as MuiCard, Tab as MuiTab, Tabs } from "@mui/material";
import { findColor } from "@/hooks/functions";
import { BugColumn } from "@pages/components/BugList/BugColumn";
import styled from "@emotion/styled";
import { spacing } from "@mui/system";

const Tab = styled(MuiTab)`
  flex: 1 0 auto;
`;

const Card = styled(MuiCard)(spacing);

const BugsTabs = ({ state, setState, mobile }) => {
  const [colors, setColor] = useState("#4caf50");

  useEffect(() => {
    setColor(findColor(state));
  }, [state]);

  return (
    <React.Fragment>
      <Tabs
        sx={{
          width: "100%",
          "& .MuiTabs-indicator": { backgroundColor: colors },
          "& .MuiTabs-flexContainer": { justifyContent: "space-between" },
        }}
        // centered
        onChange={(e, newState) => {
          setState(newState);
        }}
        value={state}
        // variant="fullWidth"
        allowScrollButtonsMobile
        scrollButtons
        selectionFollowsFocus
        variant="scrollable"
      >
        <Tab
          value="DONE"
          sx={{ "&.Mui-selected": { color: colors } }}
          label="Исправлено в выпущенной версии"
        />
        <Tab
          sx={{ "&.Mui-selected": { color: colors } }}
          value="NEW"
          label="На рассмотрении"
        />
        <Tab
          sx={{ "&.Mui-selected": { color: colors } }}
          value="INWORK"
          label="Принята к исправлению"
        />
        <Tab
          sx={{ "&.Mui-selected": { color: colors } }}
          value="REJECTED"
          label="Не является ошибкой"
        />
      </Tabs>
    </React.Fragment>
  );
};

export const BugList = ({ version, view, bugs, newBugs }) => {
  const [state, setState] = useState("DONE");
  return (
    <Card>
      <BugsTabs state={state} setState={setState} />
      <BugColumn
        state={state}
        version={version}
        view={view}
        bugs={bugs}
        newBugs={newBugs}
      />
    </Card>
  );
};
