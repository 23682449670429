import React from "react";
import { Box, Typography } from "@mui/material";

export const BugInfo = ({ description, workaround, result }) => {
  return (
    <React.Fragment>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          minWidth: "75%",
          justifyContent: "space-between",
          paddingLeft: "16px",
          paddingRight: "16px",
        }}
      >
        {description}
        {workaround && (
          <Typography mb={0} color="textSecondary">
            Способ обхода:
          </Typography>
        )}
        {workaround}
        {result && (
          <Typography mb={0} color="textSecondary">
            Решение:
          </Typography>
        )}
        {result}
      </Box>
    </React.Fragment>
  );
};
