import React, { useEffect } from "react";
import styled from "@emotion/styled";
import { useQuery, useMutation } from "@apollo/client";
import { RELEASES_GQL, GETBUGVOTES, ADDBUGVOTE } from "@data/gql_queries";
import { ThumbDown, ThumbUp } from "@mui/icons-material";
import {
  Box,
  Typography,
  Card,
  FormControl as MuiFormControl,
  InputLabel,
  Input as MuiInput,
  Select,
  MenuItem,
  IconButton,
} from "@mui/material";

const Input = styled(MuiInput)`
  &:before {
    display: none;
  }
  padding: 0px;
`;

const FormControl = styled(MuiFormControl)`
  margin-top: 0px;
  margin-bottom: 5px;
`;

export const BugPropertiesPanel = (props) => {
  const { loading, error, data } = useQuery(RELEASES_GQL);
  const {
    loading: votesLoadding,
    error: votesError,
    data: votesData,
    refetch,
  } = useQuery(GETBUGVOTES, {
    variables: { bugId: props.itemB.id },
  });

  const [addBugVote] = useMutation(ADDBUGVOTE, {
    errorPolicy: "all",
    onCompleted: (data) => {
      if (data.addVote.code === 200) {
        console.log("Успешное добавление голоса с id: " + props.itemB.id);
        refetch();
        //setEGQLParams([...editedGQLParams, "state"]);
        //window.location.reload();
      } else {
        console.log(
          "Код: " + data.addVote.code + ". Сообщение: " + data.addVote.message
        );
      }
    },
    onError: (error) => {
      props.setMessageAlert(error.message);
      props.setTypeAlert("error");
    },
  });

  function addVotes(state) {
    let variables = {
      input: {
        id: props.itemB.id,
        state: state, //LIKE DISLIKE
      },
    };
    addBugVote({
      variables: variables,
    });
  }

  return (
    <Card
      sx={{
        "@media (min-width:850px)": {
          width: "20%",
          marginLeft: "20px",
        },
        "@media (max-width:850px)": { marginTop: "20px" },
        p: 3,
        overflow: "auto",
        textAlign: "left",
        border: `1px solid ${props.colorH}`,
        position: "relative",
        minHeight: "30vh",
        paddingBottom: "40px",
      }}
      variant="outlined"
    >
      <FormControl variant="standard" size="small" fullWidth>
        <InputLabel shrink>Дата создания:</InputLabel>
        <Input
          defaultValue={props.itemB.createdAt}
          readOnly={props.isAdmin ? false : true}
          inputProps={{ maxLength: 28 }}
          onChange={(e) => props.updateField("createdAt", e.target.value)}
        />
      </FormControl>
      <FormControl variant="standard" size="small" fullWidth>
        <InputLabel shrink>Зафиксировано в релизе:</InputLabel>
        <Select
          // defaultValue={props.itemB.createdForRelease.name}
          value={props.itemB.createdForRelease.name || ""}
          readOnly={props.isAdmin && props.isNew ? false : true}
          onChange={(e) =>
            props.updateField("createdForRelease", e.target.value)
          }
        >
          {!error &&
            !loading &&
            [...data.releases]
              .sort((a, b) => {
                return a.id - b.id;
              })
              .map((release, index) => (
                <MenuItem key={index} value={release.name}>
                  {release.name}
                </MenuItem>
              ))}
        </Select>
      </FormControl>
      <FormControl variant="standard" size="small" fullWidth>
        <InputLabel shrink>Доступно в релизе:</InputLabel>
        <Select
          // defaultValue={props.itemB.createdForRelease.name}
          value={props.itemB.solvedInRelease.name || ""}
          readOnly={
            props.isAdmin && props.itemB.state === "DONE" ? false : true
          }
          onChange={(e) => props.updateField("solvedInRelease", e.target.value)}
        >
          {!error &&
            !loading &&
            [...data.releases]
              .sort((a, b) => {
                return a.id - b.id;
              })
              .map((release, index) => (
                <MenuItem key={index} value={release.name}>
                  {release.name}
                </MenuItem>
              ))}
        </Select>
      </FormControl>
      <FormControl variant="standard" size="small" fullWidth>
        <InputLabel shrink>Номера обращений:</InputLabel>
        <Input
          defaultValue={props.itemB.ticketnumber}
          multiline
          maxRows={3}
          readOnly={props.isAdmin ? false : true}
          placeholder="Не заполнено"
          inputProps={{ maxLength: 86 }}
          onChange={(e) => props.updateField("ticketnumber", e.target.value)}
        />
      </FormControl>
      {!votesLoadding && !votesError && (
        <Box
          sx={{
            width: "50%",
            position: "absolute",
            bottom: "10px",
            left: "25%",
          }}
        >
          {votesData && (
            <Typography
              sx={{
                display: "flex",
                justifyContent: "space-around",
                alignItems: "center",
              }}
              color="textSecondary"
            >
              <IconButton onClick={() => addVotes("LIKE")}>
                <ThumbUp />
              </IconButton>{" "}
              {votesData.votes.like}{" "}
              <IconButton onClick={() => addVotes("DISLIKE")}>
                <ThumbDown />
              </IconButton>{" "}
              {votesData.votes.dislike}
            </Typography>
          )}
        </Box>
      )}
    </Card>
  );
};
