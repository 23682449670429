import React, { useEffect, useState } from "react";
import styled from "@emotion/styled";
import {
  IconButton,
  TextField,
  Typography,
  Card,
  Divider,
  Box,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Paper,
  Button,
} from "@mui/material";
import { DragDropFile } from "@pages/components/BugOpened/DragDropComponent";
import { AttachFile } from "@mui/icons-material";
import Draggable from "react-draggable";

const ChatMessageBubble = styled(Box)`
  display: inline-block;
  margin-right: auto;
  background: ${(props) =>
    props.highlighted
      ? props.theme.palette.secondary.main
      : props.theme.palette.action.hover};
  color: ${(props) =>
    props.highlighted
      ? props.theme.palette.common.white
      : props.theme.palette.text.primary};
  border-radius: 3px;
  padding: 4px;
  margin-bottom: 8px;
  ${(props) => props.theme.shadows[1]};
`;

const ChatMessageInner = styled(Box)`
  display: flex;
  flex-direction: column;
  margin: 8px;
`;

function PaperComponent(props) {
  return (
    <Draggable
      handle="#draggable-dialog-title"
      cancel={'[class*="MuiDialogContent-root"]'}
    >
      <Paper {...props} />
    </Draggable>
  );
}

export const BugComment = (props) => {
  const [text, setText] = useState("");
  const [open, setOpen] = useState(false);
  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <Card sx={{ marginTop: 4 }} variant="outlined">
      <Typography variant="h6" sx={{ paddingTop: 3, px: 4, paddingBottom: 1 }}>
        Внутренние комментарии
      </Typography>
      <Divider />
      {props.loading && (
        <Box>
          <Typography sx={{ margin: "8px" }}>Загрузка..</Typography>
        </Box>
      )}
      {props.comments && (
        <ChatMessageInner>
          {props.comments?.map((comm, index) => (
            <div key={index}>
              <ChatMessageBubble>
                <Box>
                  <Typography sx={{ fontSize: "8px" }}>{comm.date}</Typography>
                </Box>
                {comm.comment}
              </ChatMessageBubble>
              {!comm.files && (
                <IconButton
                  sx={{ svg: { width: "16px", height: "16px" } }}
                  onClick={handleClickOpen}
                >
                  <AttachFile />
                </IconButton>
              )}
            </div>
          ))}
          <Dialog
            open={open}
            onClose={handleClose}
            PaperComponent={PaperComponent}
            aria-labelledby="draggable-dialog-title"
          >
            <DialogTitle style={{ cursor: "move" }} id="draggable-dialog-title">
              Вложенные файлы
            </DialogTitle>
            <DialogContent>
              <DialogContentText>
                Тут будет область с вложенными к внутреннему комментарию
                файлами, когда запрос на добавления файла к комментарию
                заработает и появится возможность тестировать отображение.
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button autoFocus onClick={handleClose}>
                ОК
              </Button>
            </DialogActions>
          </Dialog>
        </ChatMessageInner>
      )}

      <Box
        sx={{
          border:
            props.loading || (!props.loading && props.comments)
              ? "1px solid rgba(0, 0, 0, 0.12)"
              : "none",
        }}
      >
        <TextField
          InputProps={{
            readOnly: props.isAdmin ? false : true,
          }}
          sx={{
            "& fieldset": {
              border: "none",
            },
          }}
          fullWidth
          placeholder="Оставить комментарий"
          id="bug-comment"
          size="small"
          multiline
          rows={1}
          onChange={(e) => {
            setText(e.target.value);
            props.updateField("comment", e.target.value);
          }}
        />
        <DragDropFile
          arrOfIdFiles={props.comments?.files || []}
          isAdmin={props.isAdmin}
          updateField={props.updateField}
          setMessageAlert={props.setMessageAlert}
          setTypeAlert={props.setTypeAlert}
          variant="comment"
          variantText={text}
        />
      </Box>
    </Card>
  );
};
