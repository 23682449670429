import { useState } from "react";
import { bugStates } from "@data/models";
import { DragDropContext, Droppable } from "react-beautiful-dnd";
import { KanbanColumn } from "@pages/components/BugKanban/KanbanColumn";
import { KanbanColumnTitle } from "@pages/components/BugKanban/KanbanColumnTitle";
import { Grid } from "@mui/material";

const onDragEnd = (result, columns, setColumns) => {
  if (!result.destination) return;
  const { source, destination } = result;
  if (source.droppableId !== destination.droppableId) {
    const sourceColumn = columns[source.droppableId];
    const destColumn = columns[destination.droppableId];
    const sourceItems = [...sourceColumn.items];
    const destItems = [...destColumn.items];
    const [removed] = sourceItems.splice(source.index, 1);
    destItems.splice(destination.index, 0, removed);
    setColumns({
      ...columns,
      [source.droppableId]: {
        ...sourceColumn,
        items: sourceItems,
      },
      [destination.droppableId]: {
        ...destColumn,
        items: destItems,
      },
    });
  } else {
    const column = columns[source.droppableId];
    const copiedItems = [...column.items];
    const [removed] = copiedItems.splice(source.index, 1);
    copiedItems.splice(destination.index, 0, removed);
    setColumns({
      ...columns,
      [source.droppableId]: {
        ...column,
        items: copiedItems,
      },
    });
  }
};

export const BugKanban = ({ version, view, bugs, newBugs }) => {
  const [columns, setColumns] = useState(bugStates);

  return (
    <Grid
      container
      spacing={3}
      sx={{
        minWidth: "100%",
        //width: "100%",
        height: "max-content",
        flexWrap: "nowrap",
        overflow: "auto",
      }}
    >
      <DragDropContext
        onDragEnd={(result) => onDragEnd(result, columns, setColumns)}
      >
        {Object.entries(bugStates).map(([index, state]) => (
          <KanbanColumnTitle key={state.id} column={state}>
            <KanbanColumn
              key={state.id}
              version={version}
              view={view}
              columnId={index}
              state={state.id}
              bugs={bugs[state.id]}
              newBugs={newBugs}
            />
          </KanbanColumnTitle>
        ))}
      </DragDropContext>
    </Grid>
  );
};
