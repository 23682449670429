import { getCurrentDate } from "@/hooks/functions";

export const statuses = [
  {
    title: "Исправлена в выпущенной версии",
    name: "DONE",
  },
  {
    title: "На рассмотрении",
    name: "NEW",
  },
  {
    title: "Принята к исправлению",
    name: "INWORK",
  },

  {
    title: "Не является ошибкой",
    name: "REJECTED",
  },
];
