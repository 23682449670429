import { CREATEFILEURL } from "@data/gql_queries";
import { useMutation } from "@apollo/client";

export function findColor(value) {
  if (value === "NEW") {
    return "#376fd0";
  } else if (value === "INWORK") {
    return "#ed6c02";
  } else if (value === "DONE") {
    return "#4caf50";
  } else if (value === "REJECTED") {
    return "#d32f2f";
  }
}

export function findStatusName(value) {
  if (value === "NEW") {
    return "На рассмотрении";
  } else if (value === "INWORK") {
    return "Принята к исправлению";
  } else if (value === "DONE") {
    return "Исправлено";
  } else if (value === "REJECTED") {
    return "Не является ошибкой";
  } else {
    return "На рассмотрении";
  }
}

export function getCurrentDate(separator = ".") {
  let newDate = new Date();
  let date = newDate.getDate();
  let month = newDate.getMonth() + 1;
  let year = newDate.getFullYear();
  return `${date < 10 ? `0${date}` : `${date}`}${separator}${
    month < 10 ? `0${month}` : `${month}`
  }${separator}${year}`;
}

export function findVal1DArray(array, value) {
  return array.find((element) => {
    if (element === value) return true;
  });
}

export const addValTo2DArray = (obj, val, curState) => {
  return { ...obj, [curState]: [...obj[curState], ...val] };
};

let _obj = {};

export const setGlobal = (obj) => {
  Object.assign(_obj, obj);
};

export const getGlobal = (varName) => {
  if (_obj[varName] !== undefined) {
    return _obj[varName];
  } else {
    return null;
  }
};

export function chekExists2DArray(inputData, arrayToFind) {
  var arrOutput = [];
  inputData.map((bug) => {
    var isInArray = false;
    Object.keys(arrayToFind).forEach((key) => {
      if (isInArray === false) {
        isInArray = arrayToFind[key]?.some((element) => {
          if (element?.id === bug?.id) {
            return true;
          } else {
            return false;
          }
        });
      }
    });

    if (!isInArray) {
      arrOutput = [...arrOutput, bug];
    }
  });
  return arrOutput;
}
