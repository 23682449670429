import React from "react";
import { useLocation } from "react-router-dom";
import styled from "@emotion/styled";
import reduceChildRoutes from "./reduceChildRoutes";

const Wrapper = styled.div`
  display: flex;
  align-items: baseline;
  @media (max-width: 960px) {
    flex-direction: column;
  }
`;

const SidebarNavList = (props) => {
  const { pages, depth } = props;
  const router = useLocation();
  const currentRoute = router.pathname;

  const childRoutes = pages.reduce(
    (items, page) => reduceChildRoutes({ items, page, currentRoute, depth }),
    []
  );

  return <Wrapper>{childRoutes}</Wrapper>;
};

export default SidebarNavList;
