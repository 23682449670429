import React from "react";
import styled from "@emotion/styled";
import { Box, Typography, Chip } from "@mui/material";

const BoxStyled = styled(Box)`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding-left: 16px;
  padding-right: 16px;
`;

export const BugStatus = ({ color, status }) => {
  return (
    <React.Fragment>
      <BoxStyled
        sx={{
          minWidth: "100%",
          paddingTop: "6px",
        }}
      >
        <Typography mb={0} color="textSecondary">
          Описание:
        </Typography>

        <Chip
          sx={{
            backgroundColor: color,
            color: "#fff",
            padding: "2px",
            height: "fit-content",
          }}
          label={status}
        />
      </BoxStyled>
    </React.Fragment>
  );
};
