import React, { useState, useEffect } from "react";
import styled from "@emotion/styled";
import { TransitionProps } from "@mui/material/transitions";

import { Box, Dialog as DialogMui, Slide, Alert } from "@mui/material";

import { emptyItem } from "@/data/models";
import { findColor } from "@/hooks/functions";
import { RELEASES_GQL } from "@data/gql_queries";
import { useQuery } from "@apollo/client";
import { BugButtons } from "@/pages/components/BugOpened/BugButtons";
import { BugDiscription } from "@/pages/components/BugOpened/BugDiscription";
import { BugPropertiesPanel } from "@/pages/components/BugOpened/BugPropertiesPanel";
import { BugTitle } from "@/pages/components/BugOpened/BugTitle";
import { BugStatusPanel } from "@/pages/components/BugOpened/BugStatusPanel";
import { BugAllComments } from "@pages/components/BugOpened/BugAllComments";
import { useAuth } from "react-oidc-context";
import { hasRoleAdmin } from "@/utils/jwt";

const BoxStyled2 = styled(Box)`
  padding: 20px;
  *::-webkit-scrollbar {
    width: 6px;
    backgroundColor: transparent;
  },
  *::-webkit-scrollbar-track {
    WebkitBoxShadow: inset 0 0 2px rgba(0,0,0,0.3);
    borderRadius: 5px;
    backgroundColor: #F5F5F5;
    display: none;
  },
  *::-webkit-scrollbar-thumb {
    backgroundColor: #55555570;
    borderRadius: 6px;
  },
`;

const Dialog = styled(DialogMui)`
  @media (min-width: 960px) {
    left: 40%;
  }
`;

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement<any, any>,
  },
  ref: React.Ref<unknown>
) {
  return <Slide direction="left" ref={ref} {...props} />;
});

export const BugOpened = ({ onClose, open, selectedValue, item, newBugs }) => {
  const [isNew, setIsNew] = useState(false);
  const {
    loading: relLoading,
    error: relError,
    data: relData,
  } = useQuery(RELEASES_GQL);
  const [itemB, setItem] = useState(() => {
    if (!item) {
      return emptyItem;
    } else {
      return item;
    }
  });
  const [itemOld] = useState(() => {
    if (!item) {
      return emptyItem;
    } else {
      return item;
    }
  });

  useEffect(() => {
    if (open && (!item || item === emptyItem)) {
      setIsNew(true);
    }
  }, [open]);

  const [messageAlert, setMessageAlert] = useState("");
  const [typeAlert, setTypeAlert] = useState("error");

  useEffect(() => {
    if (messageAlert) {
      console.log(messageAlert);
      setTimeout(() => {
        setMessageAlert("");
      }, 5000);
    }
  }, [messageAlert]);
  const [editedParams, setEParams] = useState([]);
  const [colorH, setColorH] = useState("#376fd0");
  const auth = useAuth();
  const isAdmin = hasRoleAdmin(auth.user?.access_token);
  const [isEdit, setIsEdit] = useState(false);

  useEffect(() => {
    setColorH(findColor(itemB.state));
  }, [itemB]);

  // Проверка на изменения
  useEffect(() => {
    if (open && itemOld) {
      var arrParams = [];
      var edited = false;
      Object.keys(itemB).map((key) => {
        if (itemB[key] !== itemOld[key]) {
          //console.log(key);
          arrParams = [...arrParams, key];
          edited = true;
        }
      });
      console.log(arrParams);
      if (edited) {
        setIsEdit(true);
        setEParams(arrParams);
      } else {
        setIsEdit(false);
      }
    }
  }, [itemB]);

  const updateField = (paramName, value) => {
    let newArr = { ...itemB }; // copying the old datas array
    if (Array.isArray(paramName)) {
      paramName.map((param, index) => {
        newArr = addToArray(newArr, paramName[index], value[index]);
      });
    } else {
      addToArray(newArr, paramName, value);
    }
    setItem(newArr);
  };

  const addToArray = (newArr, paramName, value) => {
    if (
      (paramName === "createdForRelease" || paramName === "solvedInRelease") &&
      !relError &&
      !relLoading
    ) {
      let checkExistRelName = relData.releases.find((element) => {
        if (element?.name === value) {
          return element;
        }
      });
      if (checkExistRelName) {
        newArr = {
          ...newArr,
          [paramName]: {
            name: value,
            id: checkExistRelName?.id,
          },
        };
      } else {
        console.log("Не найден релиз с таким наименованием");
        newArr = {
          ...newArr,
          [paramName]: {
            name: value,
            id: "",
          },
        };
      }
    } else {
      newArr[paramName] = value;
    }
    return newArr;
  };

  const handleClose = () => {
    setEParams([]);
    setIsNew(false);
    setItem(itemOld);
    onClose(selectedValue);
  };

  const handleListItemClick = (value: string) => {
    onClose(value);
  };

  const changeStatus = (value: string) => {
    setColorH(findColor(value));
    updateField("state", value);
  };

  return (
    <React.Fragment>
      {open && (
        <Dialog
          id={itemB.id}
          fullScreen
          TransitionComponent={Transition}
          onClose={handleClose}
          open={open}
          sx={{
            backgroundColor: "transparent",
          }}
        >
          {/* Название бага */}
          <BugTitle
            isAdmin={isAdmin}
            name={itemB.name}
            id={itemB.id}
            updateField={updateField}
          />
          {/* Панель этапов */}
          <Box
            sx={{ minHeight: "2px", backgroundColor: colorH, zIndex: "1" }}
          />
          <BugStatusPanel
            isAdmin={isAdmin}
            colorH={colorH}
            state={itemB.state}
            isNew={isNew}
            changeStatus={changeStatus}
          />
          {/* Описание и панель свойств*/}
          <BoxStyled2>
            <Box
              sx={{
                display: "flex",
                "@media (max-width:850px)": { flexDirection: "column" },
              }}
            >
              {/* Описание */}
              <BugDiscription
                isAdmin={isAdmin}
                itemB={itemB}
                updateField={updateField}
                setMessageAlert={setMessageAlert}
                setTypeAlert={setTypeAlert}
                open={open}
              />
              {/* Панель свойств*/}
              <BugPropertiesPanel
                isAdmin={isAdmin}
                isNew={isNew}
                colorH={colorH}
                itemB={itemB}
                updateField={updateField}
                setMessageAlert={setMessageAlert}
                setTypeAlert={setTypeAlert}
                open={open}
              />
            </Box>
            {/* <DragDropFile isAdmin={isAdmin} /> */}
            {/* Комментарии */}
            <BugAllComments
              id={itemB.id}
              isAdmin={isAdmin}
              open={open}
              isNew={isNew}
              updateField={updateField}
              setMessageAlert={setMessageAlert}
              setTypeAlert={setTypeAlert}
            />
          </BoxStyled2>
          {/* Панель кнопок */}
          <BugButtons
            isAdmin={isAdmin}
            isNew={isNew}
            itemB={itemB}
            itemOld={itemOld}
            isEdit={isEdit}
            handleListItemClick={handleListItemClick}
            onClose={onClose}
            editedParams={editedParams}
            newBugs={newBugs}
            handleClose={handleClose}
            setMessageAlert={setMessageAlert}
            setTypeAlert={setTypeAlert}
          />{" "}
          {messageAlert && (
            <Alert
              sx={{ position: "fixed", left: "5px", bottom: "5px" }}
              severity={typeAlert}
            >
              {messageAlert}
            </Alert>
          )}
        </Dialog>
      )}
    </React.Fragment>
  );
};
