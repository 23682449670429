import { getCurrentDate } from "@/hooks/functions";

export const bugStates = [
  {
    id: "DONE",
    title: "Исправлено в выпущенной версии",
    color: "success",
  },
  {
    id: "NEW",
    title: "На рассмотрении",
    color: "primary",
  },
  {
    id: "INWORK",
    title: "Принята к исправлению",
    color: "warning",
  },
  {
    id: "REJECTED",
    title: "Не является ошибкой",
    color: "error",
  },
];

export const initBugs = () => {
  let res = {};
  bugStates.map((state) => {
    res[`${state.id}`] = [];
  });
  return res;
};

export const emptyItem = {
  id: 0,
  name: "",
  description: "",
  state: "NEW",
  result: "",
  favorite: "",
  createdForRelease: { name: "", id: 0 },
  solvedInRelease: { name: "", id: 0 },
  createdAt: getCurrentDate(),
  ticketnumber: "",
};
