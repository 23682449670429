import React from "react";
import {
  ADDFAVOURITE,
  DELFAVOURITE,
  CHECKFAVOURITES,
  CREATEFILEURL,
} from "@data/gql_queries";
import { useMutation, useQuery } from "@apollo/client";
import { StarBorder, Star } from "@mui/icons-material";
import { DragDropFile } from "@pages/components/BugOpened/DragDropComponent";
import { useAuth } from "react-oidc-context";
import {
  Box,
  TextField,
  Typography,
  Card,
  Divider,
  IconButton,
} from "@mui/material";

export const BugDiscription = (props) => {
  const auth = useAuth();
  const { loading, error, data, refetch } = useQuery(CHECKFAVOURITES, {
    variables: { bugId: props.itemB.id },
  });
  const [addFavourites] = useMutation(ADDFAVOURITE, {
    variables: {
      input: {
        id: props.itemB.id,
      },
    },
    errorPolicy: "all",
    onCompleted: (data) => {
      if (data.addFavourites.code === 200) {
        console.log(
          "Успешное добавление в избранное элемента с id: " + props.itemB.id
        );
        refetch();
      } else {
        console.log(
          "Код: " +
            data.addFavourites.code +
            ". Сообщение: " +
            data.addFavourites.message
        );
      }
    },
    onError: (error) => {
      props.setMessageAlert(error.message);
      props.setTypeAlert("error");
    },
  });

  const [delFavourites] = useMutation(DELFAVOURITE, {
    variables: {
      input: {
        id: props.itemB.id,
      },
    },
    errorPolicy: "all",
    onCompleted: (data) => {
      if (data.delFavourites.code === 200) {
        console.log(
          "Успешное удаление из избранного элемента с id: " + props.itemB.id
        );
        refetch();
      } else {
        console.log(
          "Код: " +
            data.delFavourites.code +
            ". Сообщение: " +
            data.delFavourites.message
        );
      }
    },
    onError: (error) => {
      props.setMessageAlert(error.message);
      props.setTypeAlert("error");
    },
  });

  const changeVal = (paramName: string, value: string) => {
    props.updateField(paramName, value);
    //console.log("Отработало изменение в BugDiscription");
  };
  return (
    <Card
      sx={{
        "@media (min-width:850px)": { width: "80%" },
      }}
      variant="outlined"
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <Typography
          variant="h6"
          sx={{
            width: "100%",
            paddingTop: 3,
            px: 4,
            paddingBottom: 1,
          }}
        >
          Описание
        </Typography>
        {!loading && !error && data.favourites.totalCount === 0 && (
          <IconButton sx={{ marginRight: "5px" }} onClick={addFavourites}>
            <StarBorder />
          </IconButton>
        )}
        {!loading && !error && data.favourites.totalCount > 0 && (
          <IconButton sx={{ marginRight: "5px" }} onClick={delFavourites}>
            <Star sx={{ color: "#ed6c02" }} />
          </IconButton>
        )}
      </Box>
      <Divider />
      <TextField
        InputProps={{
          readOnly: props.isAdmin ? false : true,
        }}
        sx={{
          "& fieldset": { border: "none" },
        }}
        fullWidth
        id="bug-description"
        multiline
        rows={12}
        defaultValue={props.itemB.description}
        onChange={(e) => changeVal("description", e.target.value)}
      />
      <DragDropFile
        arrOfIdFiles={props.itemB.files}
        isAdmin={props.isAdmin}
        updateField={props.updateField}
        variant="description"
        setMessageAlert={props.setMessageAlert}
        setTypeAlert={props.setTypeAlert}
      />
      {/* <UploadButtons /> */}
    </Card>
  );
};
