import React from "react";
import { AuthProvider } from "react-oidc-context";
import { keycloakConfig } from "@/config";

const oidcConfig = {
  authority: keycloakConfig.authority,
  redirect_uri: keycloakConfig.redirectUri,
  client_id: keycloakConfig.clientId,
  client_secret: keycloakConfig.clientSecret,
  post_logout_redirect_uri: keycloakConfig.redirectUri,
  scope: "openid profile email",
  onSigninCallback: (user) => {
    console.log("onSigninCallback()");
    window.history.replaceState({}, document.title, window.location.pathname);
    window.location = user.state || "/";
  },
};

const OidcContext = React.createContext();

function OidcProvider({ children }) {
  return (
    <OidcContext.Provider value={{}}>
      <AuthProvider {...oidcConfig}>{children}</AuthProvider>
    </OidcContext.Provider>
  );
}

export { OidcProvider, OidcContext };
