import React from "react";

import { DialogTitle, TextField, Box } from "@mui/material";

export const BugTitle = ({ isAdmin, name, updateField, id }) => {
  const changeVal = (paramName: string, value: string) => {
    updateField(paramName, value);
    //console.log("Отработало изменение в BugTitle");
  };
  return (
    <DialogTitle
      sx={{
        paddingTop: "20px",
        paddingBottom: "0px",
      }}
    >
      <TextField
        InputProps={{
          readOnly: isAdmin ? false : true,
          style: { fontSize: 16, fontWeight: "700" },
        }}
        placeholder="Не заполнено название"
        size="small"
        sx={{
          "& fieldset": { border: "none" },
          width: "100%",
          ".MuiInputBase-input": { padding: "0px 0px 5px 0px" },
        }}
        id="bug-name"
        fullWidth
        defaultValue={name}
        onChange={(e) => changeVal("name", e.target.value)}
      />
      <Box
        sx={{
          position: "absolute",
          top: "10px",
          right: "10px",
          opacity: "0.1",
          fontSize: "10px",
        }}
      >
        id: {id}
      </Box>
    </DialogTitle>
  );
};
