import {
  Card as MuiCard,
  CardContent as MuiCardContent,
  Grid,
  Typography as MuiTypography,
} from "@mui/material";
import styled from "@emotion/styled";
import { spacing } from "@mui/system";

const CardContent = styled(MuiCardContent)`
  &:last-child {
    padding-bottom: ${(props) => props.theme.spacing(4)};
  }
`;

const Typography = styled(MuiTypography)(spacing);

const Card = styled(MuiCard)(spacing);

const CardStyled = styled(Card)`
  // background-color: #fff;
  margin-top: -10px;
  @media (max-width: 960px) {
    min-width: 90vw;
  }
`;

const TypographyStyled = styled(Typography)`
  color: white;
  text-align: center;
  font-weight: 700;
  padding: 2px;
  background: ${(props) =>
    props.theme.palette[props.color ? props.color : "primary"].light};
  border-radius: 10px;
`;

export const KanbanColumnTitle = ({ column, children }) => {
  return (
    <Grid
      item
      xs={12}
      lg={6}
      xl={3}
      sx={{
        overflow: "overlay",
        paddingTop: "0px",
        "*::-webkit-scrollbar": {
          width: "6px",
          backgroundColor: "transparent",
        },
        "*::-webkit-scrollbar-track": {
          WebkitBoxShadow: "inset 0 0 2px rgba(0,0,0,0.3)",
          borderRadius: "5px",
          backgroundColor: "#F5F5F5",
          display: "none",
        },
        "*::-webkit-scrollbar-thumb": {
          backgroundColor: "#55555570",
          borderRadius: "6px",
        },
        marginTop: "-10px",
        "@media (max-width: 960px)": {
          minWidth: "95vw",
        },
      }}
    >
      <CardStyled>
        <CardContent pb={0}>
          <TypographyStyled
            color={column.color}
            variant="subtitle1"
            gutterBottom
          >
            {column.title}
          </TypographyStyled>
          <Typography variant="body2" mb={0}>
            {column.description}
          </Typography>
        </CardContent>
      </CardStyled>
      <CardStyled sx={{ overflow: "auto", maxHeight: "65vh" }}>
        <CardContent pb={0}>{children}</CardContent>
      </CardStyled>
    </Grid>
  );
};
