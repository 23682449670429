import React, {
  DragEvent,
  useState,
  useCallback,
  useEffect,
  useRef,
} from "react";
import { useMutation } from "@apollo/client";
import { styled } from "@mui/material/styles";
import { CREATEUPLOADURL, CREATEFILEURL } from "@data/gql_queries";
import { Box } from "@mui/material/";
import "@pages/styles/UploadButtons.css";
import ImageViewer from "react-simple-image-viewer";
import { useAuth } from "react-oidc-context";

const Input = styled("input")({
  display: "none",
});

export function DragDropFile(props) {
  const auth = useAuth();
  const [filesID, setFilesID] = useState([]);
  const [filesURL, setFilesURL] = useState([]);

  useEffect(() => {
    if (Array.isArray(props.arrOfIdFiles) && props.arrOfIdFiles.length > 0) {
      let arr = [];
      props.arrOfIdFiles.forEach((element) => {
        if (element.file_id) {
          arr = [...arr, element.file_id];
          createFileUrl({
            variables: {
              input: {
                id_token: auth.user?.id_token,
                file_id: element.file_id,
              },
            },
          });
        }
      });
      setFilesID([...filesID, ...arr]);
    }
  }, [props.files]);

  useEffect(() => {
    if (filesID !== props.arrOfIdFiles && props.variant === "description") {
      props.updateField("files", filesID);
      //console.log("Отработало изменение files");
    } else if (filesID !== props.arrOfIdFiles) {
      let variantText = props.variantText;
      if (!variantText) {
        variantText = " ";
      }
      props.updateField(
        [`${props.variant}_files`, props.variant],
        [filesID, variantText]
      );
      //console.log(`Отработало изменение ${props.variant}_files`);
    }
  }, [filesID]);

  const [createUploadUrl] = useMutation(CREATEUPLOADURL, {
    fetchPolicy: "network-only",
    errorPolicy: "all",
    onCompleted: (data) => {
      if (data.createUploadURL.code === 200) {
        console.log("Успешное создание ссылки в GQL");
        // console.log("url: " + data.createUploadURL.url);
        // console.log(data.createUploadUrl.file_id);
        setFilesID([...filesID, data.createUploadURL.file_id]);
        // setNeedToClose(true);
        // setEGQLParams([...editedGQLParams, "state"]);
        //window.location.reload();
      } else {
        console.log(
          "Код: " +
            data.createUploadURL.code +
            ". Сообщение: " +
            data.createUploadURL.message
        );
      }
    },
    onError: (error) => {
      props.setMessageAlert(error.message);
      props.setTypeAlert("error");
    },
  });

  const [createFileUrl] = useMutation(CREATEFILEURL, {
    errorPolicy: "all",
    onCompleted: (data) => {
      if (data.createFileURL.code === 200) {
        console.log("Получена ссылка на файл по id");
        setFilesURL([...filesURL, data.createFileURL.url]);
      } else {
        console.log(
          "Код: " +
            data.createFileURL.code +
            ". Сообщение: " +
            data.createFileURL.message
        );
      }
    },
    onError: (error) => {
      props.setMessageAlert(error.message);
      props.setTypeAlert("error");
    },
  });

  // drag state
  const [dragActive, setDragActive] = useState(false);
  // ref
  const inputRef = useRef(null);

  const [isViewerOpen, setIsViewerOpen] = useState(false);

  const [URL, setURL] = useState([]);
  const openImageViewer = useCallback((index, url) => {
    setURL([url]);
    setIsViewerOpen(true);
  }, []);

  const closeImageViewer = () => {
    setIsViewerOpen(false);
  };

  const handleDrag = (e: DragEvent<HTMLDivElement>) => {
    e.preventDefault();
    e.stopPropagation();
    if (e.type === "dragenter" || e.type === "dragover") {
      setDragActive(true);
    } else if (e.type === "dragleave") {
      setDragActive(false);
    }
  };

  // triggers when file is dropped
  const handleDrop = function (e) {
    e.preventDefault();
    e.stopPropagation();
    setDragActive(false);
    if (e.dataTransfer.files && e.dataTransfer.files[0]) {
      let allfiles = [];
      for (let i = 0; i < e.dataTransfer.files.length; i++) {
        allfiles.push(window.URL.createObjectURL(e.dataTransfer.files[i]));
        // console.log(e.dataTransfer.files[i].name);
        // console.log(allfiles);
        createUploadUrl({
          variables: {
            input: {
              id_token: auth.user?.id_token,
              filename: e.dataTransfer.files[i].name,
            },
          },
        });
      }
      if (allfiles.length > 0) {
        setFilesURL([...filesURL, ...allfiles]);
      }
      // console.log(allfiles);
    }
  };

  // triggers when file is selected with click
  const handleChange = function (e) {
    e.preventDefault();
    if (e.target.files && e.target.files[0]) {
      let allfiles = [];
      for (let i = 0; i < e.target.files.length; i++) {
        allfiles.push(window.URL.createObjectURL(e.target.files[i]));
        createUploadUrl({
          variables: {
            input: {
              id_token: auth.user?.id_token,
              filename: e.target.files[i].name,
            },
          },
        });
      }
      if (allfiles.length > 0) {
        setFilesURL([...filesURL, ...allfiles]);
      }
    }
  };

  // triggers the input when the button is clicked
  const onButtonClick = () => {
    inputRef.current.click();
  };

  return (
    <React.Fragment>
      <div>
        {props.isAdmin && (
          <Box
            id="form-file-upload"
            onDragEnter={handleDrag}
            onSubmit={(e) => e.preventDefault()}
          >
            <Input
              ref={inputRef}
              type="file"
              id={`input-file-upload ${props.variant}`}
              multiple={true}
              onChange={handleChange}
              className="input-file-upload"
            />
            <label
              id={`label-file-upload ${props.variant}`}
              htmlFor={`input-file-upload ${props.variant}`}
              className={
                dragActive
                  ? "label-file-upload drag-active "
                  : "label-file-upload"
              }
            >
              <div>
                Перетащите изображения в эту область или
                <button className="upload-button" onClick={onButtonClick}>
                  загрузите по нажатию
                </button>
              </div>
            </label>
            {/* <Box sx={{ border: "1px solid rgba(0, 0, 0, 0.12)" }}></Box> */}
            {dragActive && (
              <div
                id={`drag-file-element ${props.variant}`}
                className="drag-file-element"
                onDragEnter={handleDrag}
                onDragLeave={handleDrag}
                onDragOver={handleDrag}
                onDrop={handleDrop}
              ></div>
            )}
          </Box>
        )}
        {filesURL && filesURL.length > 0 && (
          <div id={props.variant} className="file-box">
            {filesURL.map((url, key) => {
              return (
                <img
                  src={url}
                  onClick={() => openImageViewer(key, url)}
                  key={key}
                  style={{
                    margin: "2px",
                    maxWidth: "75px",
                    maxHeight: "75px",
                  }}
                  alt=""
                />
              );
            })}
          </div>
        )}
        {isViewerOpen && (
          <ImageViewer
            src={URL}
            // currentIndex={currentImage}
            onClose={closeImageViewer}
            disableScroll={true}
            backgroundStyle={{
              backgroundColor: "rgba(0,0,0,0.3)",
            }}
            closeOnClickOutside={true}
          />
        )}
      </div>
    </React.Fragment>
  );
}
