import React from "react";
import styled from "@emotion/styled";

import { IconButton, Grid, Paper } from "@mui/material";

import { statuses } from "@/mocks/items";

const Item = styled(Paper)(({ theme }) => ({
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: "center",
}));
export const BugStatusPanel = ({
  isAdmin,
  state,
  isNew,
  changeStatus,
  colorH,
}) => {
  return (
    <Grid
      container
      sx={{
        marginTop: "-2px",
        overflow: "visible",
        "@media (max-width:850px)": { display: "none" },
      }}
      spacing={0}
      direction="row"
      justifyContent="center"
      alignItems="stretch"
    >
      {statuses.map((status, index) => (
        <Grid key={index} item xs={3}>
          {status.name === state && (
            <Item
              sx={{
                backgroundColor: colorH,
                color: "#fff",
                borderRadius: "0px",
              }}
            >
              {status.title}
            </Item>
          )}
          {status.name !== state && (
            <Paper sx={{ minHeight: "20px" }}>
              {isAdmin && !isNew && (
                <IconButton
                  sx={{
                    width: "100%",
                    borderRadius: "0px",
                    padding: "0px",
                  }}
                  onClick={() => changeStatus(status.name)}
                >
                  <Item>{status.title}</Item>
                </IconButton>
              )}
              {!isAdmin && <Item>{status.title}</Item>}
            </Paper>
          )}
        </Grid>
      ))}
    </Grid>
  );
};
