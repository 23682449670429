import { gql } from "@apollo/client";

export const RELEASES_GQL = gql`
  query Query {
    releases(productID: 1) {
      id
      name
      createdAt
    }
  }
`;

export const CREATE_COMPANY = gql`
  mutation CreateCompany($input: NewCompany!) {
    createCompany(input: $input) {
      code
      success
      message
      company {
        uuid
        name
        active
      }
    }
  }
`;

export const BUGS = gql`
  query Bugs($page: Int!, $size: Int!) {
    bugs(page: $page, size: $size) {
      totalCount
      totalPages
      page
      size
      hasMore
      bugs {
        createdAt
        deleted
        id
        name
        description
      }
    }
  }
`;

export const BUGSN = gql`
  query Bugs($productId: Int!, $page: Int!, $size: Int!) {
    bugs(productID: $productId, page: $page, size: $size) {
      bugs {
        id
        name
        description
        createdAt
        updatedAt
        state
        createdForRelease {
          id
          name
        }
        solvedInRelease {
          id
          name
        }
        externalID
      }
      totalCount
      hasMore
    }
  }
`;

export const CREATEBUG = gql`
  mutation CreateBug($input: NewBug!) {
    createBug(input: $input) {
      code
      message
      success
      bug {
        id
        name
        description
        createdAt
        updatedAt
        state
        externalID
        solvedInRelease {
          name
        }
        createdForRelease {
          name
        }
      }
    }
  }
`;

export const UPDATEBUG = gql`
  mutation UpdateBug($input: UpdateBug!) {
    updateBug(input: $input) {
      code
      success
      message
      bug {
        id
        name
        description
        createdAt
        updatedAt
        state
        createdForRelease {
          name
          id
        }
        solvedInRelease {
          name
          id
        }
        externalID
        files {
          file_id
          full_file_id
        }
      }
    }
  }
`;

export const BUGS_OF_STATE_GQL = gql`
  query Bugs($state: BugState, $productId: Int!, $page: Int!, $size: Int!) {
    bugs(state: $state, productID: $productId, page: $page, size: $size) {
      bugs {
        id
        name
        description
        createdAt
        updatedAt
        state
        createdForRelease {
          id
          name
        }
        solvedInRelease {
          id
          name
        }
        externalID
        files {
          file_id
          full_file_id
        }
      }
      totalCount
      hasMore
    }
  }
`;

export const FAVOURITEBUGS = gql`
  query Favourites($productId: Int, $page: Int!, $size: Int!) {
    favourites(productID: $productId, page: $page, size: $size) {
      totalCount
      totalPages
      hasMore
      bugs {
        id
        name
        description
        createdAt
        updatedAt
        state
        createdForRelease {
          id
          name
        }
        solvedInRelease {
          id
          name
        }
      }
    }
  }
`;
export const UPDATEBUGSTATE = gql`
  mutation UpdateBugState($input: NewState!) {
    setBugState(input: $input) {
      code
      message
      success
    }
  }
`;

export const GETBUGCOMMENTS = gql`
  query Comments($bugId: Int!) {
    comments(bugID: $bugId) {
      workaround {
        date
        comment
        files {
          file_id
          full_file_id
          # preview_data
        }
      }
      result {
        comment
        date
        files {
          file_id
          full_file_id
          # preview_data
        }
      }
      comments {
        date
        comment
        # user {
        #   id
        #   name
        # }
        files {
          file_id
          full_file_id
          # preview_data
        }
      }
    }
  }
`;
export const UPDATEBUGCOMMENT = gql`
  mutation AddComment($input: NewComment!) {
    addComment(input: $input) {
      code
      success
      message
    }
  }
`;

export const DELETEBUG = gql`
  mutation DeleteBug($input: DeleteBug!) {
    deleteBug(input: $input) {
      code
      success
      message
    }
  }
`;

export const GETBUGVOTES = gql`
  query Votes($bugId: Int!) {
    votes(bugID: $bugId) {
      dislike
      like
    }
  }
`;

export const ADDBUGVOTE = gql`
  mutation AddVote($input: NewVote!) {
    addVote(input: $input) {
      code
      success
      message
    }
  }
`;

export const ADDFAVOURITE = gql`
  mutation AddFavourites($input: NewFavourites!) {
    addFavourites(input: $input) {
      code
      success
      message
    }
  }
`;

export const DELFAVOURITE = gql`
  mutation DelFavourites($input: NewFavourites!) {
    delFavourites(input: $input) {
      code
      success
      message
    }
  }
`;

export const CHECKFAVOURITES = gql`
  query CheckFavourites($bugId: Int) {
    favourites(bugID: $bugId) {
      totalCount
    }
  }
`;

export const CREATEUPLOADURL = gql`
  mutation CreateUploadURL($input: NewUploadURL!) {
    createUploadURL(input: $input) {
      code
      success
      message
      url
      file_id
    }
  }
`;

export const CREATEFILEURL = gql`
  mutation CreateFileURL($input: NewFileURL!) {
    createFileURL(input: $input) {
      code
      success
      message
      url
    }
  }
`;
