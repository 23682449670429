import React, { useState, useEffect } from "react";
import {
  ThumbDown,
  ThumbUp,
  StarBorder,
  Add,
  Delete,
  Close,
  Done,
} from "@mui/icons-material";
import {
  Button,
  Box,
  IconButton,
  Alert,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  DialogContentText,
} from "@mui/material";
import { findVal1DArray } from "@/hooks/functions";
import {
  CREATEBUG,
  UPDATEBUG,
  UPDATEBUGSTATE,
  UPDATEBUGCOMMENT,
  DELETEBUG,
} from "@data/gql_queries";
import { gql, useMutation, useQuery } from "@apollo/client";
import styled from "@emotion/styled";

const BoxStyled = styled(Box)`
  @media (min-width: 960px) {
    margin-top: 10px;
  }
  @media (max-width: 960px) {
    ${
      "" /* right: 20px;
    top: 5px; */
    }
    margin: 1px;
    border: 1px solid grey;
  }
  @keyframes bounce {
    0%,
    20%,
    60%,
    100% {
      transform: translateY(0);
      transform: translateY(0);
    }

    40% {
      transform: translateY(-4px);
      transform: translateY(-4px);
    }

    80% {
      transform: translateY(-2px);
      transform: translateY(-2px);
    }
  }
`;

const BoxPanel = styled(Box)`
  @media (min-width: 960px) {
    margin-top: 10px;
    margin-left: -45px;
  }
  @media (max-width: 960px) {
    right: 10px;
    top: 2px;
    display: flex;
    flex-direction: row-reverse;
  }
  position: fixed;
`;

const IconButtonStyled = styled(IconButton)`
  background: ${(props) => props.theme.header.background};
  border: 1px solid #ffffff3b;
  &:hover {
    background: ${(props) => props.theme.header.background};
    @media (min-width: 960px) {
      animation: bounce 0.5s;
    }
  }
`;

const IconButtonColored = styled(IconButton)`
  svg {
    color: white;
  }
  border: 1px solid #ffffff3b;
  &:hover {
    @media (min-width: 960px) {
      animation: bounce 0.5s;
    }
  }
`;

export const BugButtons = (props) => {
  const [needToClose, setNeedToClose] = useState(false);
  const [editedGQLParams, setEGQLParams] = useState([]);
  const [open, setOpen] = useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleAgree = () => {
    setOpen(false);
    deleteBug();
  };

  useEffect(() => {
    if (needToClose) {
      try {
        props.newBugs({
          state: props.itemOld.state,
          newBugs: [props.itemB],
          new_state: props.itemB.state,
          update: true,
        });
      } catch (err) {
        console.log("Неудачное изменение массива Bugs на фронтэнде");
      }
      props.setMessageAlert("Обновлены " + editedGQLParams);
      props.setTypeAlert("success");
      setTimeout(() => {
        props.onClose();
      }, 1000);
    }
  }, [needToClose]);

  // MUTATION UPDATE BUG NAME & DESC
  const [updateBug] = useMutation(UPDATEBUG, {
    variables: {
      input: {
        description: props.itemB.description,
        name: props.itemB.name,
        createForReleaseID: props.itemB.createdForRelease.id,
        createdAt: props.itemB.createdAt,
        id: props.itemB.id,
        files: props.itemB.files,
      },
    },
    fetchPolicy: "network-only",
    errorPolicy: "all",
    onCompleted: (data) => {
      if (data.updateBug.code === 200) {
        // console.log(data.updateBug);
        setNeedToClose(true);
        setEGQLParams([...editedGQLParams, "name", "description"]);
        //props.onClose(data.updateBug.code);
        //window.location.reload();
      } else {
        console.log(
          "Код: " +
            data.updateBug.code +
            ". Сообщение: " +
            data.updateBug.message
        );
      }

      // setTimeout(() => {
      //   setStatusSent(false);
      // }, 2500);
    },
    onError: (error) => {
      props.setMessageAlert(error.message);
      props.setTypeAlert("error");
    },
    update(cache, { data: { updateBug } }) {
      if (updateBug.code === 200) {
        cache.modify({
          fields: {
            bugs(existingBugs) {
              const newBugRef = cache.writeFragment({
                data: updateBug.bug,
                fragment: gql`
                  fragment Bug on bugs {
                    id
                    name
                    description
                    state
                    createdAt
                    createdForRelease
                    updatedAt
                  }
                `,
              });
              console.log("Обновление кэша прошло");
              return { bugs: [existingBugs.bugs, newBugRef] };
            },
          },
        });
      } else {
        console.log("Обновление кэша не удалось");
      }
    },
  });

  // MUTATION UPDATE BUG STATE
  const [setBugState] = useMutation(UPDATEBUGSTATE, {
    errorPolicy: "all",
    onCompleted: (data) => {
      if (data.setBugState.code === 200) {
        console.log("Успешное изменение state в GQL");
        setNeedToClose(true);
        setEGQLParams([...editedGQLParams, "state"]);
        //window.location.reload();
      } else {
        console.log(
          "Код: " +
            data.setBugState.code +
            ". Сообщение: " +
            data.setBugState.message
        );
      }
    },
    onError: (error) => {
      props.setMessageAlert(error.message);
      props.setTypeAlert("error");
    },
  });

  const [setBugComment] = useMutation(UPDATEBUGCOMMENT, {
    errorPolicy: "all",
    onCompleted: (data) => {
      if (data.addComment.code === 200) {
        console.log("Успешное изменение Комментария в GQL");
        setNeedToClose(true);
        //setEGQLParams([...editedGQLParams, "state"]);
        //window.location.reload();
      } else {
        console.log(
          "Код: " +
            data.addComment.code +
            ". Сообщение: " +
            data.addComment.message
        );
      }
    },
    onError: (error) => {
      props.setMessageAlert(error.message);
      props.setTypeAlert("error");
    },
  });

  // MUTATION CREATE BUG
  const [сreateBug] = useMutation(CREATEBUG, {
    variables: {
      input: {
        description: props.itemB.description,
        name: props.itemB.name,
        createForReleaseID: props.itemB.createdForRelease.id,
      },
    },
    errorPolicy: "all",
    onCompleted: (data) => {
      if (data.createBug.code === 200) {
        //console.log(data);
        setNeedToClose(true);
        setEGQLParams(["name", "description", "createForReleaseID"]);
        try {
          props.newBugs({
            state: props.itemB.state,
            newBugs: [data.createBug.bug],
          });
        } catch (err) {
          console.log("Неудачное изменение массива Bugs на фронтэнде");
        }
        props.handleClose();
        // props.onClose(data.createBug.code);
      } else {
        console.log(
          "Код: " +
            data.createBug.code +
            ". Сообщение: " +
            data.createBug.message
        );
      }
    },
    onError: (error) => {
      props.setMessageAlert(error.message);
      props.setTypeAlert("error");
    },
    update(cache, { data: { createBug } }) {
      if (createBug.code === 200) {
        cache.modify({
          fields: {
            bugs(existingBugs) {
              const newBugRef = cache.writeFragment({
                data: createBug.bug,
                fragment: gql`
                  fragment Bug on bugs {
                    id
                    name
                    description
                    state
                    createdAt
                    createdForRelease
                    updatedAt
                  }
                `,
              });
              console.log("Обновление кэша прошло");
              return { bugs: [existingBugs.bugs, newBugRef] };
            },
          },
        });
      }
    },
  });

  const [deleteBug] = useMutation(DELETEBUG, {
    variables: {
      input: {
        id: props.itemB.id,
      },
    },
    errorPolicy: "all",
    onCompleted: (data) => {
      if (data.deleteBug.code === 200) {
        console.log(
          "Успешное удаление элемента в GQL с id: " + props.itemOld.id
        );
        try {
          props.newBugs({
            state: props.itemOld.state,
            newBugs: [props.itemB],
            delete: true,
          });
        } catch (err) {
          console.log("Неудачное изменение массива Bugs на фронтэнде");
        }
        props.handleClose();
        //setEGQLParams([...editedGQLParams, "state"]);
        //window.location.reload();
      } else {
        console.log(
          "Код: " +
            data.deleteBug.code +
            ". Сообщение: " +
            data.deleteBug.message
        );
      }
    },
    onError: (error) => {
      props.setMessageAlert(error.message);
      props.setTypeAlert("error");
    },
  });

  // BUTTON SAVE ONCLICK
  const saveParams = () => {
    let edited = false;
    //Если изменено название или описание, то вызывать мутацию UPDATEBUG
    if (
      findVal1DArray(props.editedParams, "name") ||
      findVal1DArray(props.editedParams, "description") ||
      findVal1DArray(props.editedParams, "files")
    ) {
      updateBug();
      edited = true;
    }
    //Если изменена стадия, то вызывать мутацию UPDATEBUGSTATE
    if (findVal1DArray(props.editedParams, "state")) {
      //console.log("Тут выполняется мутация смены стадии");
      var variables = {
        input: {
          id: props.itemB.id,
          state: props.itemB.state,
        },
      };
      if (
        props.itemB.state === "DONE" &&
        props.itemB.solvedInRelease &&
        props.itemB.solvedInRelease.id
      ) {
        variables = {
          ...variables,
          input: {
            ...variables.input,
            solvedInReleaseID: props.itemB.solvedInRelease.id,
          },
        };
      } else if (
        props.itemB.state === "DONE" &&
        (!props.itemB.solvedInRelease || !props.itemB.solvedInRelease.id)
      ) {
        // console.log("Необходимо заполнить solvedInRelease");
        props.setMessageAlert("Необходимо заполнить solvedInRelease");
        props.setTypeAlert("error");
        return;
      }
      setBugState({
        variables: variables,
      });
      edited = true;
    }
    ["workaround", "result", "comment"].forEach(async (element) => {
      if (
        findVal1DArray(props.editedParams, element) ||
        findVal1DArray(props.editedParams, `${element}_files`)
      ) {
        let variables = {
          input: {
            id: props.itemB.id,
            state: element.toUpperCase(),
            comment: props.itemB[element],
          },
        };
        if (props.itemB[`${element}_files`]?.length > 0) {
          variables = {
            ...variables,
            input: {
              ...variables.input,
              files: props.itemB[`${element}_files`],
            },
          };
        }
        const result = await setBugComment({
          variables: variables,
        });
        if (result.data) {
          //console.log(result.data);
          edited = true;
          setEGQLParams([...editedGQLParams, element]);
        }
      }
    });
    if (!edited) {
      //props.setMessageAlert("Нет доступных для сохранения полей");
      //props.setTypeAlert("warning");
    }
  };

  const addBug = () => {
    if (
      props.itemB.name.length &&
      props.itemB.description &&
      props.itemB.createdForRelease.id
    ) {
      сreateBug({
        variables: {
          input: {
            description: props.itemB.description,
            name: props.itemB.name,
            createForReleaseID: props.itemB.createdForRelease.id,
          },
        },
      });
    } else {
      var arr = [];
      if (!props.itemB.name) arr = [...arr, "name"];
      if (!props.itemB.description) arr = [...arr, "description"];
      if (!props.itemB.createdForRelease.id)
        arr = [...arr, "createdForRelease.id"];
      props.setMessageAlert("Не заполнено одно из обязательных полей: " + arr);
      props.setTypeAlert("error");
    }
  };

  return (
    <React.Fragment>
      {/* Панель кнопок */}
      <Box
        sx={{
          "@media (min-width:850px)": { position: "absolute" },
          bottom: "5px",
          textAlign: "center",
          width: "100%",
        }}
      >
        {props.itemB.result && !props.isNew && !props.isAdmin && (
          <Button
            sx={{ margin: "10px" }}
            endIcon={<ThumbDown />}
            variant="contained"
            color="error"
            onClick={() => props.handleListItemClick("Saved value")}
          >
            У меня не работает
          </Button>
        )}
        {props.itemB.result && !props.isNew && !props.isAdmin && (
          <Button
            sx={{ margin: "10px" }}
            startIcon={<ThumbUp />}
            variant="contained"
            color="primary"
            onClick={() => props.handleListItemClick("Saved value")}
          >
            У меня работает
          </Button>
        )}
        {/* {props.isAdmin && props.isNew && (
          <Button
            sx={{ margin: "10px" }}
            startIcon={<Add />}
            variant="contained"
            color="primary"
            onClick={addBug}
          >
            Добавить
          </Button>
        )} */}
        {/* {props.isAdmin && !props.isNew && props.isEdit && (
          <Button
            sx={{ margin: "10px" }}
            startIcon={<Edit />}
            variant="contained"
            color="primary"
            onClick={saveParams}
          >
            Сохранить изменения
          </Button>
        )} */}
        {/* {props.isAdmin && !props.isNew && (
          <Button
            sx={{ margin: "10px" }}
            startIcon={<Delete />}
            variant="contained"
            color="primary"
            onClick={(e) => {
              //updateBug();
            }}
          >
            Пометить на удаление
          </Button>
        )} */}
      </Box>
      {/* Кнопка закрытия */}
      <BoxPanel>
        <BoxStyled>
          <IconButtonStyled onClick={props.handleClose}>
            <Close />
          </IconButtonStyled>
        </BoxStyled>
        {props.isAdmin && props.isNew && (
          <BoxStyled>
            <IconButtonColored
              sx={{
                background: "#376fd0",
                "&:hover": {
                  background: "#376fd0",
                },
              }}
              onClick={addBug}
            >
              <Add />
            </IconButtonColored>
          </BoxStyled>
        )}
        {props.isAdmin && !props.isNew && props.isEdit && (
          <BoxStyled>
            <IconButtonColored
              sx={{
                background: "#376fd0",
                "&:hover": {
                  background: "#376fd0",
                },
              }}
              onClick={saveParams}
            >
              <Done />
            </IconButtonColored>
          </BoxStyled>
        )}
        {props.isAdmin && !props.isNew && (
          <BoxStyled
            sx={{
              "@media (min-width: 960px)": {
                position: "fixed",
                bottom: "10px",
                right: "10px",
              },
            }}
          >
            <IconButtonColored
              sx={{
                background: "#d32f2f",
                "&:hover": {
                  background: "#d32f2f",
                },
              }}
              // onClick={deleteBug}
              onClick={handleClickOpen}
            >
              <Delete />
            </IconButtonColored>
          </BoxStyled>
        )}
        <Dialog open={open} onClose={handleClose}>
          <DialogTitle id="alert-dialog-title">
            Подтверждение удаления
          </DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              Вы действительно хотите удалить эту проблему?
            </DialogContentText>
          </DialogContent>
          <DialogActions style={{ justifyContent: "space-around" }}>
            <Button onClick={handleAgree}>Да</Button>
            <Button onClick={handleClose} autoFocus>
              Нет
            </Button>
          </DialogActions>
        </Dialog>
      </BoxPanel>
    </React.Fragment>
  );
};
