import React from "react";
import { Box, Button } from "@mui/material";

export const BugListButtons = ({ description, workaround, result }) => {
  return (
    <React.Fragment>
      <Box
        sx={{
          minWidth: "25%",
          alignItems: "end",
          position: "relative",
          justifyContent: "flex-end",
          display: "flex",
          top: "8px",
          paddingLeft: "16px",
          paddingRight: "16px",
        }}
      >
        <Button
          onClick={() => console.log("В избранное")}
          size="small"
          color="primary"
        >
          В избранное
        </Button>
        {/* <Button
              onClick={() => console.log("Важно")}
              size="small"
              color="primary"
            >
              Для меня это важно
            </Button> */}
      </Box>
    </React.Fragment>
  );
};
