import React, { useEffect, useState } from "react";

import { Box, TextField, Typography, Card, Divider } from "@mui/material";
import { DragDropFile } from "@pages/components/BugOpened/DragDropComponent";

export const BugResult = (props) => {
  const [text, setText] = useState(props.result?.comment);
  useEffect(() => {
    setText(props.result?.comment);
  }, [props.result?.comment]);
  useEffect(() => {
    if (props.loading) {
      setText("Загрузка..");
    } else {
      setText(props.result?.comment);
    }
  }, [props.loading]);
  return (
    <Box>
      <Card sx={{ marginTop: 4 }} variant="outlined">
        <Typography
          variant="h6"
          sx={{ paddingTop: 3, px: 4, paddingBottom: 1 }}
        >
          Решение
        </Typography>
        <Divider />
        <TextField
          InputProps={{
            readOnly: props.isAdmin ? false : true,
          }}
          sx={{
            "& fieldset": { border: "none" },
          }}
          fullWidth
          id="bug-result"
          multiline
          rows={6}
          defaultValue={text}
          onChange={(e) => {
            setText(e.target.value);
            props.updateField("result", e.target.value);
          }}
        />
        <DragDropFile
          arrOfIdFiles={props.result?.files || []}
          isAdmin={props.isAdmin}
          updateField={props.updateField}
          setMessageAlert={props.setMessageAlert}
          setTypeAlert={props.setTypeAlert}
          variant="result"
          variantText={text}
        />
      </Card>
    </Box>
  );
};
