import React from "react";
import styled from "@emotion/styled";
import { withTheme } from "@emotion/react";
import { getGlobal } from "@/hooks/functions";
import {
  Grid,
  AppBar as MuiAppBar,
  IconButton as MuiIconButton,
  Toolbar,
  Typography,
} from "@mui/material";

import { Menu as MenuIcon } from "@mui/icons-material";

const AppBar = styled(MuiAppBar)`
  background: ${(props) => props.theme.header.background};
  color: ${(props) => props.theme.header.color};
  @media (min-width: 960px) {
    display: none;
  }
  // @media (max-width: 960px) {
  //   height: 64px;
  // }
`;

const IconButton = styled(MuiIconButton)`
  svg {
    width: 22px;
    height: 22px;
  }
`;

const Navbar = ({ onDrawerToggle }) => {
  const [locationName, setLocationName] = React.useState("");
  React.useEffect(() => {
    setLocationName(getGlobal("location"));
  }, [getGlobal("location")]);
  return (
    <React.Fragment>
      <AppBar position="sticky" elevation={0}>
        <Toolbar sx={{ "@media (min-width:960px)": { height: "0px" } }}>
          <Grid container alignItems="center">
            <Grid
              item
              sx={{ display: { xs: "flex", md: "none" }, alignItems: "center" }}
            >
              <IconButton
                color="inherit"
                aria-label="Open drawer"
                onClick={onDrawerToggle}
                size="large"
              >
                <MenuIcon />
              </IconButton>
              <Typography variant="h6" display="inline">
                {locationName}
              </Typography>
            </Grid>
          </Grid>
        </Toolbar>
      </AppBar>
    </React.Fragment>
  );
};

export default withTheme(Navbar);
