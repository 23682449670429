import React, { useEffect, useState } from "react";
import styled from "@emotion/styled";
import { NavLink } from "react-router-dom";
import { Helmet } from "react-helmet-async";
import { useQuery } from "@apollo/client";
import { RELEASES_GQL, BUGSN } from "@data/gql_queries";
import { BugOpened } from "@pages/components/BugOpened/BugOpened";
import { setGlobal } from "@/hooks/functions";
// import { mockItems, releases, versionNumbers } from "@/mocks/items";
import {
  Divider as MuiDivider,
  Typography as MuiTypography,
} from "@mui/material";
import { spacing } from "@mui/system";
import {
  TimelineDot,
  TimelineContent,
  TimelineConnector,
  TimelineSeparator,
  TimelineItem,
  timelineItemClasses,
  Timeline,
  TimelineOppositeContent,
  timelineOppositeContentClasses,
} from "@mui/lab";

const Divider = styled(MuiDivider)(spacing);

const Typography = styled(MuiTypography)(spacing);

const TypographyStyled = styled(Typography)`
  text-decoration: underline;
  color: ${(props) => props.theme.header.color};
  // font-family: "Gilroy ExtraBold";
`;

function CustomizedTimelineHeader() {
  return (
    <Timeline
      sx={{
        [`& .${timelineOppositeContentClasses.root}`]: {
          flex: 0.4,
        },
        [`& .${timelineItemClasses.root}:before`]: {
          flex: 0.2,
        },
      }}
    >
      <TimelineItem style={{ minHeight: "0px" }}>
        <TimelineOppositeContent variant="h6" color="textSecondary">
          Дата выхода
        </TimelineOppositeContent>
        <TimelineSeparator>
          <TimelineDot color="primary"></TimelineDot>
        </TimelineSeparator>
        <TimelineContent sx={{ py: "6px", px: 4 }}>
          <Typography variant="h4" component="span">
            Номер версии
          </Typography>
        </TimelineContent>
      </TimelineItem>
    </Timeline>
  );
}

const Task = ({ item }) => {
  const [open, setOpen] = React.useState(false);

  useEffect(() => {
    if (item) {
      // console.log(item);
    }
  }, [item]);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };
  return (
    <div>
      <Typography
        onClick={handleClickOpen}
        sx={{ "&:hover": { textDecoration: "underline" } }}
      >
        {item.name}
      </Typography>
      <BugOpened open={open} onClose={handleClose} item={item} />
    </div>
  );
};

function CustomizedTimeline() {
  const [items, setItems] = useState([]);
  const {
    loading: relLoading,
    error: relError,
    data: relData,
  } = useQuery(RELEASES_GQL);
  const {
    loading: bugLoading,
    error: bugError,
    data: bugData,
  } = useQuery(BUGSN, {
    variables: { productId: 1, page: 1, size: 50 },
  });

  useEffect(() => {
    if (!bugLoading && !bugError && bugData) {
      //console.log(bugData);
      setItems(bugData?.bugs?.bugs);
    }
  }, [bugLoading, bugError, bugData]);

  useEffect(() => {
    if (!relLoading && !relError && relData) {
      //console.log(relData.releases);
    }
  }, [relLoading, relError, relData]);
  return (
    <Timeline
      sx={{
        [`& .${timelineOppositeContentClasses.root}`]: {
          flex: 0.4,
        },
        [`& .${timelineItemClasses.root}:before`]: {
          flex: 0.2,
        },
      }}
    >
      {!relLoading &&
        !relError &&
        !bugError &&
        !bugLoading &&
        relData.releases &&
        [...relData.releases]
          .sort((a, b) => {
            return a.id - b.id;
          })
          .map((release, index) => (
            // {releases.map((release, index) => (
            <TimelineItem key={index}>
              <TimelineOppositeContent color="textSecondary">
                {release.createdAt}
              </TimelineOppositeContent>
              <TimelineSeparator>
                {index === 0 && (
                  <TimelineDot variant="outlined" color="primary" />
                )}
                {index > 0 && <TimelineDot color="primary" />}
                <TimelineConnector sx={{ bgcolor: "#1976d2" }} />
              </TimelineSeparator>
              <TimelineContent sx={{ py: "6px", px: 4 }}>
                <TypographyStyled
                  variant="h4"
                  component={NavLink}
                  to={"/" + release.name}
                >
                  {release.name}
                </TypographyStyled>
                {items &&
                  items.map((item, index) => (
                    <div key={index}>
                      {item.solvedInRelease.name &&
                        item.state === "DONE" &&
                        item.solvedInRelease.name === release.name && (
                          <Task item={item} />
                        )}
                    </div>
                  ))}
              </TimelineContent>
            </TimelineItem>
          ))}
    </Timeline>
  );
}

function Changelog() {
  useEffect(() => {
    setGlobal({ location: "Каталог ошибок" });
  }, []);
  return (
    <React.Fragment>
      <Helmet title="Каталог" />

      {/* <Grid container spacing={6} justifyContent="center">
        <Grid item xs={12} lg={9} xl={7}> */}
      <Typography
        style={{ margin: "20vw" }}
        sx={{ "@media (max-width:960px)": { display: "none" } }}
        variant="h3"
        gutterBottom
        display="inline"
      >
        Каталог ошибок
      </Typography>
      <Divider my={4} />
      <CustomizedTimelineHeader />
      <CustomizedTimeline />
      {/* </Grid>
      </Grid> */}
    </React.Fragment>
  );
}

export default Changelog;
