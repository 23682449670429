import React from "react";
import { useAuth } from "react-oidc-context";
import { apolloConfig } from "@/config";
import {
  ApolloProvider,
  ApolloClient,
  InMemoryCache,
  createHttpLink,
} from "@apollo/client";
import { setContext } from "@apollo/client/link/context";

const DataContext = React.createContext();

function DataProvider({ children }) {
  const auth = useAuth();

  const httpLink = createHttpLink({
    uri: apolloConfig.uri,
  });

  const authLink = setContext((_, { headers }) => {
    let token = auth.user?.access_token;
    return {
      headers: {
        ...headers,
        authorization: token ? `Bearer ${token}` : "",
      },
    };
  });

  const apolloClient = new ApolloClient({
    link: authLink.concat(httpLink),
    cache: new InMemoryCache({
      typePolicies: {
        Company: {
          keyFields: ["uuid"],
        },
        User: {
          keyFields: ["uuid"],
        },
        Identity: {
          keyFields: ["username"],
        },
      },
    }),
    name: "web",
    version: "1.0",
  });

  return (
    <DataContext.Provider value={{}}>
      <ApolloProvider client={apolloClient}>{children}</ApolloProvider>
    </DataContext.Provider>
  );
}

export { DataProvider, DataContext };
