import React from "react";
import styled from "@emotion/styled";
import { Power } from "react-feather";
import {
  Badge,
  Grid,
  Avatar,
  Typography,
  IconButton as MuiIconButton,
  Menu,
  MenuItem,
  ListItemIcon,
  Divider,
  Button,
} from "@mui/material";

import { THEMES } from "../../constants";
import useTheme from "../../hooks/useTheme";
import {
  NightsStay,
  Brightness4,
  Tune,
  Settings,
  Logout,
  AdminPanelSettings,
} from "@mui/icons-material";

import { useAuth } from "react-oidc-context";

const IconButton = styled(MuiIconButton)`
  svg {
    color: white;
    width: 22px;
    height: 22px;
  }
`;

const Footer = styled.div`
  display: flex;
  align-items: center;
  background-color: ${(props) =>
    props.theme.sidebar.footer.background} !important;
  padding: ${(props) => props.theme.spacing(2.75)}
    ${(props) => props.theme.spacing(4)};
  border-right: 1px solid rgba(0, 0, 0, 0.12);
`;

const FooterText = styled(Typography)`
  color: ${(props) => props.theme.sidebar.footer.color};
`;

const FooterSubText = styled(Typography)`
  color: ${(props) => props.theme.sidebar.footer.color};
  font-size: 0.7rem;
  display: block;
  padding: 1px;
`;

const FooterBadge = styled(Badge)`
  margin-right: ${(props) => props.theme.spacing(1)};
  span {
    background-color: ${(props) =>
      props.theme.sidebar.footer.online.background};
    border: 1.5px solid ${(props) => props.theme.palette.common.white};
    height: 12px;
    width: 12px;
    border-radius: 50%;
  }
`;

const SidebarFooter = ({ ...rest }) => {
  const auth = useAuth();
  const { theme, setTheme } = useTheme();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  // React.useEffect(() => {
  //   console.log(auth?.user?.profile.name);
  // }, [auth]);
  // var { isAuthenticated, isAdmin } = useAuth();
  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const UsehandleAdminClose = (state) => {
    setAnchorEl(null);
  };
  const handleLogout = async () => {
    auth.clearStaleState();
    auth.removeUser();
    auth.signoutRedirect({ id_token_hint: auth.user?.id_token });
    setAnchorEl(null);
  };
  //console.log(theme);
  return (
    <Footer {...rest}>
      <Grid
        container
        spacing={2}
        sx={{
          flexWrap: "nowrap",
          justifyContent: "center",
        }}
      >
        {!auth.isAuthenticated && (
          <Grid item>
            {theme === THEMES.DEFAULT && (
              <IconButton
                aria-haspopup="true"
                onClick={() => setTheme(THEMES.DARK)}
                size="large"
              >
                <NightsStay />
              </IconButton>
            )}
            {theme === THEMES.DARK && (
              <IconButton
                aria-haspopup="true"
                onClick={() => setTheme(THEMES.DEFAULT)}
                size="large"
              >
                <Brightness4 />
              </IconButton>
            )}
          </Grid>
        )}
        {/* <Grid item>
          {!!user && <Avatar alt={user.displayName} src={user.avatar} />}
          {!user && (
            <Avatar
              alt="Lucy Lavender"
              src="/static/img/avatars/avatar-1.jpg"
            />
          )}
        </Grid> */}
        <Grid item>
          {auth.isAuthenticated && (
            <Button
              aria-haspopup="true"
              onClick={handleClick}
              sx={{ color: "white", padding: "10px" }}
              aria-controls={open ? "account-menu" : undefined}
              aria-expanded={open ? "true" : undefined}
            >
              {auth?.user?.profile.name}
            </Button>
          )}
          {!auth.isAuthenticated && (
            <Button
              aria-haspopup="true"
              onClick={() => {
                auth.signinRedirect({ state: window.location.href });
              }}
              size="large"
              sx={{ color: "white", padding: "10px" }}
            >
              Войти
            </Button>
          )}
          {/*{!!user && (
            <FooterText variant="body2">{user.displayName}</FooterText>
          )}
           Demo data
          {!user && <FooterText variant="body2">Lucy Lavender</FooterText>}
          <FooterSubText variant="caption">UX Designer</FooterSubText> */}
        </Grid>
        <Menu
          anchorEl={anchorEl}
          id="account-menu"
          open={open}
          onClose={handleClose}
          onClick={handleClose}
          PaperProps={{
            elevation: 0,
            sx: {
              overflow: "visible",
              filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
              mt: 1.5,
              "& .MuiAvatar-root": {
                width: 32,
                height: 32,
                ml: -0.5,
                mr: 1,
              },
              "&:before": {
                content: '""',
                display: "block",
                position: "absolute",
                top: 0,
                right: 14,
                width: 10,
                height: 10,
                bgcolor: "background.paper",
                transform: "translateY(-50%) rotate(45deg)",
                zIndex: 0,
              },
            },
          }}
          transformOrigin={{ horizontal: "right", vertical: "top" }}
          anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
        >
          {/* <MenuItem onClick={UsehandleAdminClose}>
            <ListItemIcon>
              <AdminPanelSettings />
            </ListItemIcon>
            Включить режим администратора
          </MenuItem> */}
          <MenuItem onClick={handleClose}>
            <ListItemIcon>
              <Settings fontSize="small" />
            </ListItemIcon>
            Настройка уведомлений
          </MenuItem>
          {theme === THEMES.DEFAULT && (
            <MenuItem onClick={() => setTheme(THEMES.DARK)}>
              <ListItemIcon>
                <NightsStay />
              </ListItemIcon>
              Темная тема
            </MenuItem>
          )}
          {theme === THEMES.DARK && (
            <MenuItem onClick={() => setTheme(THEMES.DEFAULT)}>
              <ListItemIcon>
                <Brightness4 />
              </ListItemIcon>
              Светлая тема
            </MenuItem>
          )}
          <Divider />
          <MenuItem onClick={handleLogout}>
            <ListItemIcon>
              <Logout fontSize="small" />
            </ListItemIcon>
            Выйти
          </MenuItem>
        </Menu>
      </Grid>
    </Footer>
  );
};

export default SidebarFooter;
