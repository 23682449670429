import React, { useState, useEffect, useRef } from "react";
import { KanbanCard } from "@pages/components/BugKanban/KanbanCard";
import { BUGS_OF_STATE_GQL } from "@data/gql_queries";
import { useQuery } from "@apollo/client";

export const KanbanColumn = ({
  version,
  view,
  columnId,
  state,
  bugs,
  newBugs,
}) => {
  const lastElement = useRef();
  const observer = useRef();
  const [page, setPage] = useState(1);
  const { loading, error, data, refetch } = useQuery(BUGS_OF_STATE_GQL, {
    variables: { productId: 1, state: state, page: page, size: 10 },
  });

  useEffect(() => {
    if (page !== 1) {
      refetch();
    }
  }, [page]);

  useEffect(() => {
    if (!loading && !error && data && data.bugs && data.bugs.bugs) {
      newBugs({ state: state, newBugs: data.bugs.bugs });
    }
  }, [data]);

  useEffect(() => {
    if (loading || error) return;
    if (observer.current) observer.current.disconnect();
    var cb = function (entries, observer) {
      if (entries[0].isIntersecting && data.bugs.hasMore) {
        setPage(page + 1);
      }
    };
    if (bugs && bugs.length > 0) {
      observer.current = new IntersectionObserver(cb);
      observer.current.observe(lastElement.current);
    }
  }, [bugs]);

  if (loading && page === 1) {
    return <div>Loading</div>;
  }

  return (
    <div
      style={{
        minHeight: 50,
      }}
    >
      {bugs &&
        bugs.length > 0 &&
        bugs.map((bug) => {
          return <KanbanCard key={bug.id} item={bug} newBugs={newBugs} />;
        })}
      <div ref={lastElement} style={{ height: 1, background: "transparent" }} />
    </div>
  );
};
