import styled from "@emotion/styled";
import { Box, Typography } from "@mui/material";

const BoxStyled = styled(Box)`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding-left: 16px;
  padding-right: 16px;
`;

export const BugTitle = ({ name, createdAt }) => {
  return (
    <BoxStyled
      sx={{
        minWidth: "100%",
        paddingTop: "10px",
        alignItems: "end",
      }}
    >
      <Typography
        sx={{ marginBottom: "0px" }}
        gutterBottom
        variant="h5"
        component="h2"
      >
        {name}
      </Typography>
      <Typography
        sx={{ float: "right", textAlign: "end" }}
        color="textSecondary"
      >
        Зарегистрирована: {createdAt}
      </Typography>
    </BoxStyled>
  );
};
