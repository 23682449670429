import React from "react";
import { BugResult } from "@/pages/components/BugOpened/BugResult";
import { BugWorkaround } from "@/pages/components/BugOpened/BugWorkaround";
import { BugComment } from "@/pages/components/BugOpened/BugComment";
import { GETBUGCOMMENTS } from "@data/gql_queries";
import { useQuery } from "@apollo/client";

export const BugAllComments = (
  props
  // { id, isAdmin, isNew, updateField, open }
) => {
  const { loading, error, data, refetch } = useQuery(GETBUGCOMMENTS, {
    variables: { bugId: props.id },
  });
  React.useEffect(() => {
    refetch();
  }, [props.open]);
  // React.useEffect(() => {
  //   if (!loading && !error) console.log(data.comments.workaround.files);
  // }, [data]);
  return (
    <React.Fragment>
      {/* Способ обхода */}
      {(data?.comments.workaround || props.isAdmin) && (
        <BugWorkaround
          isAdmin={props.isAdmin}
          workaround={data?.comments?.workaround}
          updateField={props.updateField}
          setMessageAlert={props.setMessageAlert}
          setTypeAlert={props.setTypeAlert}
          loading={loading}
        />
      )}
      {/* Результат */}
      {(data?.comments.result || props.isAdmin) && !props.isNew && (
        <BugResult
          isAdmin={props.isAdmin}
          result={data?.comments?.result}
          updateField={props.updateField}
          setMessageAlert={props.setMessageAlert}
          setTypeAlert={props.setTypeAlert}
          loading={loading}
        />
      )}
      {/* Внутренние комментарии */}
      {props.isAdmin && (
        <BugComment
          isAdmin={props.isAdmin}
          comments={data?.comments?.comments}
          updateField={props.updateField}
          setMessageAlert={props.setMessageAlert}
          setTypeAlert={props.setTypeAlert}
          loading={loading}
        />
      )}
    </React.Fragment>
  );
};
