import React, { useState, useEffect, useRef } from "react";
import { Grid } from "@mui/material";
import { BugCard } from "@pages/components/BugList/BugCard";
import styled from "@emotion/styled";
import { BUGS_OF_STATE_GQL } from "@data/gql_queries";
import { useQuery } from "@apollo/client";

const GridStyled = styled(Grid)`
  margin-bottom: 10px;
  margin-top: 5px;
  margin-left: 0px;
  width: 100%;
`;

export const BugColumn = ({ state, version, view, bugs, newBugs }) => {
  const lastElement = useRef();
  const observer = useRef();
  const [page, setPage] = useState(1);
  const { loading, error, data, refetch } = useQuery(BUGS_OF_STATE_GQL, {
    variables: { productId: 1, state: state, page: page, size: 10 },
  });

  useEffect(() => {
    setPage(1);
  }, [state]);

  useEffect(() => {
    if (page !== 1) {
      refetch();
    }
  }, [page]);

  useEffect(() => {
    if (!loading && !error && data && data.bugs && data.bugs.bugs) {
      newBugs({ state: state, newBugs: data.bugs.bugs });
    }
  }, [data]);

  useEffect(() => {
    if (loading || error) return;
    if (observer.current) observer.current.disconnect();

    var cb = function (entries, observer) {
      if (entries[0].isIntersecting && data.bugs.hasMore) {
        setPage(page + 1);
      }
    };
    if (bugs[state].length > 0) {
      observer.current = new IntersectionObserver(cb);
      observer.current.observe(lastElement.current);
    }
  }, [bugs[state]]);

  return (
    <GridStyled container spacing={0}>
      {bugs &&
        bugs[state].map((item, index, arr) => {
          const lastIndex = arr.length - 1;
          if (state !== "DONE") {
            return (
              <Grid key={index} item xs={12} xl={12}>
                {item.state === state && (
                  <Grid>
                    <BugCard item={item} newBugs={newBugs} />
                    {index === lastIndex && (
                      <div
                        ref={lastElement}
                        style={{ height: 1, background: "transparent" }}
                      />
                    )}
                  </Grid>
                )}
              </Grid>
            );
          } else {
            return (
              <Grid key={index} item xs={12} xl={12}>
                {item.state === state &&
                  item.solvedInRelease.name === version && (
                    <Grid>
                      <BugCard item={item} newBugs={newBugs} />
                      {index === lastIndex && (
                        <div
                          ref={lastElement}
                          style={{ height: 1, background: "transparent" }}
                        />
                      )}
                    </Grid>
                  )}
              </Grid>
            );
          }
        })}
    </GridStyled>
  );
};
