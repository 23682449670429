import styled from "@emotion/styled";
import {
  Divider as MuiDivider,
  Box,
  Typography as MuiTypography,
} from "@mui/material";
import { spacing } from "@mui/system";

const Divider = styled(MuiDivider)(spacing);

const Typography = styled(MuiTypography)`
  margin-bottom: 0px;
`;

const BoxStart = styled(Box)`
  display: flex;
  min-width: 2%;
  flex-direction: row;
`;

const BoxStyled = styled(Box)`
  display: flex;
  flex-direction: column;
`;

export const BugListItemStart = ({ color }) => {
  return (
    <BoxStart>
      <BoxStyled sx={{ minWidth: "20%", paddingTop: "10px" }}>
        <Typography gutterBottom variant="h5" component="h2">
           
        </Typography>
        <Divider />
      </BoxStyled>
      <BoxStyled
        sx={{
          backgroundColor: color,
          minWidth: "80%",
        }}
      >
        <Typography gutterBottom variant="h5" component="h2">
           
        </Typography>
      </BoxStyled>
    </BoxStart>
  );
};
