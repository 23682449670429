import React, { useState, useEffect, useRef } from "react";
import styled from "@emotion/styled";
import { NavLink } from "react-router-dom";
import { Helmet } from "react-helmet-async";
import { versionNumbers, releases } from "@/constants";
// import { mockItems } from "@/mocks/items";
import { setGlobal } from "@/hooks/functions";
import { FAVOURITEBUGS } from "@data/gql_queries";
import { useQuery } from "@apollo/client";
import { BugOpened } from "@pages/components/BugOpened/BugOpened";
import { findColor, findStatusName } from "@/hooks/functions";
import {
  Checkbox,
  Grid,
  IconButton,
  Link,
  Breadcrumbs as MuiBreadcrumbs,
  Divider as MuiDivider,
  Paper as MuiPaper,
  Table,
  TableBody,
  TableContainer,
  TableCell,
  TableHead,
  TablePagination,
  TableRow,
  TableSortLabel,
  Toolbar,
  Tooltip,
  Typography,
  FormControlLabel,
  Switch,
} from "@mui/material";
import {
  Delete as DeleteIcon,
  FilterList as FilterListIcon,
} from "@mui/icons-material";
import { spacing } from "@mui/system";

const Divider = styled(MuiDivider)(spacing);

const Breadcrumbs = styled(MuiBreadcrumbs)(spacing);

const Paper = styled(MuiPaper)(spacing);

const Spacer = styled.div`
  flex: 1 1 100%;
`;

function createData(name, calories, fat, carbs, protein) {
  return { name, calories, fat, carbs, protein };
}

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === "desc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => ({
    el,
    index,
  }));
  stabilizedThis.sort((a, b) => {
    const order = comparator(a.el, b.el);
    if (order !== 0) return order;
    return a.index - b.index;
  });
  return stabilizedThis.map((element) => element.el);
}

const headCells = [
  {
    id: "name",
    numeric: false,
    disablePadding: true,
    label: "Наименование",
  },
  {
    id: "ticket-numders",
    numeric: true,
    disablePadding: false,
    label: "Номера обращений",
  },
  {
    id: "registration-data",
    numeric: true,
    disablePadding: false,
    label: "Дата регистрации",
  },
  {
    id: "status",
    numeric: true,
    disablePadding: false,
    label: "Статус",
  },
];

const EnhancedTableHead = (props) => {
  const {
    onSelectAllClick,
    order,
    orderBy,
    numSelected,
    rowCount,
    onRequestSort,
  } = props;
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow>
        {headCells.map((headCell) => (
          <TableCell
            key={headCell.id}
            align={headCell.numeric ? "center" : "left"}
            padding="normal"
            sortDirection={orderBy === headCell.id ? order : false}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : "asc"}
              onClick={createSortHandler(headCell.id)}
            >
              {headCell.label}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
};

function EnhancedTable() {
  const [order, setOrder] = useState("asc");
  const [orderBy, setOrderBy] = useState("calories");
  const [selected, setSelected] = useState([]);
  // const [page, setPage] = useState(0);
  const [pageSQL, setPageSQL] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [bugs, setBugs] = useState([]);
  const [bug_to_open, setBugToOpen] = useState([]);
  const [open, setOpen] = useState(false);
  const lastElement = useRef();
  const observer = useRef();
  const { loading, error, data, refetch } = useQuery(FAVOURITEBUGS, {
    variables: { productId: 1, page: pageSQL, size: 10 },
  });

  useEffect(() => {
    if (pageSQL !== 1) {
      refetch();
    }
  }, [pageSQL]);

  useEffect(() => {
    if (!open) setBugToOpen([]);
  }, [open]);

  useEffect(() => {
    if (!loading && !error && data && data.favourites && data.favourites.bugs) {
      let cloneBugs = [];
      Object.assign(cloneBugs, bugs);
      let newBugs = [...data.favourites.bugs].filter((new_item) => {
        let res = cloneBugs.findIndex((exist_item) => {
          return exist_item.id === new_item.id;
        });
        return res === -1;
      });

      cloneBugs = [...cloneBugs, ...newBugs];
      setBugs(cloneBugs);
    }
  }, [data]);

  useEffect(() => {
    if (loading || error) return;
    if (observer.current) observer.current.disconnect();
    var cb = function (entries, observer) {
      if (entries[0].isIntersecting && data.favourites.hasMore) {
        setPageSQL(pageSQL + 1);
      }
    };
    if (bugs && bugs.length > 0) {
      observer.current = new IntersectionObserver(cb);
      observer.current.observe(lastElement.current);
    }
  }, [bugs]);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = (value: string) => {
    setOpen(false);
  };

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleClick = (event, name) => {
    const selectedIndex = selected.indexOf(name);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, name);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }

    setSelected(newSelected);
  };

  // const handleChangePage = (event, newPage) => {
  //   setPage(newPage);
  // };

  // const handleChangeRowsPerPage = (event) => {
  //   setRowsPerPage(parseInt(event.target.value, 10));
  //   setPage(0);
  // };

  const isSelected = (name) => selected.indexOf(name) !== -1;

  // const emptyRows =
  //   rowsPerPage - Math.min(rowsPerPage, rows.length - page * rowsPerPage);
  if (loading && pageSQL === 1) {
    return <div>Loading</div>;
  }

  return (
    <div>
      <Paper>
        <TableContainer>
          <Table
            sx={{ background: "transparent" }}
            aria-labelledby="tableTitle"
            size="small"
            aria-label="enhanced table"
          >
            <EnhancedTableHead
              numSelected={selected.length}
              order={order}
              orderBy={orderBy}
              onRequestSort={handleRequestSort}
              rowCount={bugs.length}
            />
            <TableBody>
              {stableSort(bugs, getComparator(order, orderBy))
                //.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((bug, index) => {
                  const isItemSelected = isSelected(bug.title);
                  const labelId = `enhanced-table-checkbox-${index}`;
                  return (
                    bug && (
                      <Task
                        key={index}
                        item={bug}
                        labelId={labelId}
                        setBugToOpen={setBugToOpen}
                        setOpen={setOpen}
                      />
                    )
                  );
                })}
              <TableRow>
                <TableCell colSpan={6} />
              </TableRow>
              <TableRow ref={lastElement} />
              {/* 
              <div
                ref={lastElement}
                style={{ height: 1, background: "transparent" }}
              /> */}
            </TableBody>
          </Table>
          {bug_to_open.id && (
            <BugOpened open={open} onClose={handleClose} item={bug_to_open} />
          )}
        </TableContainer>
        {/* <TablePagination
          rowsPerPageOptions={[10, 25]}
          component="div"
          count={bugs.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        /> */}
      </Paper>
    </div>
  );
}

const Task = ({ item, labelId, setBugToOpen, setOpen }) => {
  const handleClickOpen = () => {
    setOpen(true);
    setBugToOpen(item);
  };
  let colorState = findColor(item.state);

  return (
    <React.Fragment>
      <TableRow
        hover
        onClick={handleClickOpen}
        role="checkbox"
        tabIndex={-1}
        key={item.title}
      >
        <TableCell component="th" id={labelId} scope="row">
          {item.name}
        </TableCell>
        <TableCell align="center">{item.ticketnumber}</TableCell>
        <TableCell align="center">{item.createdAt}</TableCell>
        <TableCell style={{ color: colorState }} align="center">
          {findStatusName(item.state)}
        </TableCell>
      </TableRow>
    </React.Fragment>
  );
};

function AdvancedTable() {
  useEffect(() => {
    setGlobal({ location: "Избранное" });
  }, []);
  return (
    <React.Fragment>
      <Helmet title="Избранное" />
      <Typography
        sx={{ "@media (max-width:960px)": { display: "none" } }}
        variant="h3"
        gutterBottom
        display="inline"
      >
        Избранное
      </Typography>
      <Divider my={4} />

      <Grid container spacing={6}>
        <Grid item xs={12}>
          <EnhancedTable />
        </Grid>
      </Grid>
    </React.Fragment>
  );
}

export default AdvancedTable;
