import React, { useEffect } from "react";
import styled from "@emotion/styled";
import { Card as MuiCard, Divider as MuiDivider, Box } from "@mui/material";
import { findColor, findStatusName } from "@/hooks/functions";
import { spacing } from "@mui/system";
import { BugOpened } from "@pages/components/BugOpened/BugOpened";
import { useLocation, useNavigate } from "react-router-dom";
import { BugListItemStart } from "@pages/components/BugList/BugListItemStart";
import { BugTitle } from "@pages/components/BugList/BugTitle";
import { BugStatus } from "@pages/components/BugList/BugStatus";
import { BugInfo } from "@pages/components/BugList/BugInfo";
import { BugListButtons } from "@pages/components/BugList/BugListButtons";

const CardStyled = styled(MuiCard)`
  display: flex;
  flexdirection: row;
  border: 1px solid #e0e0e0;
  margin: 5px;
`;
const BoxStyled = styled(Box)`
  min-width: 98%;
  flex-direction: column;
  &:hover {
    cursor: pointer;
  }
`;

const BoxStyledSecond = styled(Box)`
  display: flex;
  flex-direction: column;
  min-width:100$
  padding-bottom: 10px;
  @media (min-width: 850px) {
    flex-direction: row;
  }
`;

const Divider = styled(MuiDivider)(spacing);

export const BugCard = ({ item, newBugs }) => {
  const [open, setOpen] = React.useState(false);
  const [colorH, setColorH] = React.useState("");
  const [status, setStatus] = React.useState("");

  useEffect(() => {
    setColorH(findColor(item.state));
    setStatus(findStatusName(item.state));
  }, [item]);

  const handleClickOpen = () => {
    setOpen(true);
  };
  const navigate = useNavigate();
  const location = useLocation();
  const handleClose = (value: string) => {
    setOpen(false);
    navigate(location.pathname);
  };
  return (
    <CardStyled key={item.id} sx={{ "&:hover": { borderColor: colorH } }}>
      <BugListItemStart color={colorH} />
      <BoxStyled
        onClick={handleClickOpen}
        sx={{
          "&:hover": { borderColor: colorH },
        }}
      >
        {/* Шапка */}
        <BugTitle name={item.name} createdAt={item.createdAt} />
        {/* Разделитель */}
        <Divider />
        {/* Статус */}
        <BugStatus color={colorH} status={status} />
        {/* Описание, способ обхода, решение и кнопки */}
        <BoxStyledSecond sx={{ paddingBottom: "10px" }}>
          {/* Способ обхода, решение */}
          <BugInfo
            description={item.description}
            workaround={item.workaround}
            result={item.result}
          />
          {/* Кнопки */}
          <BugListButtons />
        </BoxStyledSecond>
      </BoxStyled>
      <BugOpened
        open={open}
        onClose={handleClose}
        item={item}
        newBugs={newBugs}
      />
    </CardStyled>
  );
};
