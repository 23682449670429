import React from "react";
import { Navigate } from "react-router-dom";

import async from "./components/Async";

// All pages that rely on 3rd party components (other than MUI) are
// loaded asynchronously, to keep the initial JS bundle to a minimum size

// Layouts
import AuthLayout from "./layouts/Auth";
import DashboardLayout from "./layouts/Dashboard";
import DocLayout from "./layouts/Doc";
//import PresentationLayout from "./layouts/Presentation";

import Page404 from "./pages/auth/Page404";
import Page500 from "./pages/auth/Page500";

import { BugOpened } from "./pages/components/BugOpened/BugOpened";

import BugsPage from "./pages/pages/BugsPage";
import BugsChangelog from "./pages/pages/BugsChangelog";
import FavoriteBugs from "./pages/pages/FavoriteBugs";
const routes = [
  //   {
  //     path: "/",
  //     element: <PresentationLayout />,
  //     children: [
  //       {
  //         path: "",
  //         element: <Landing />,
  //       },
  //     ],
  //   },
  {
    path: "/",
    element: <DashboardLayout />,
    children: [
      {
        path: "",
        element: <BugsChangelog />,
      },
      {
        path: ":version",
        element: <BugsPage />,
      },
      {
        path: "new",
        element: <BugsPage />,
      },
      {
        path: ":version/:id",
        // element: <BagOpened />,
        element: <BugsPage />,
      },
      {
        path: "favorite",
        element: <FavoriteBugs />,
      },
    ],
  },
  {
    path: "*",
    element: <AuthLayout />,
    children: [
      {
        path: "*",
        element: <Page404 />,
      },
    ],
  },
];

export default routes;
