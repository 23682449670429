import React, { useEffect, useState, useReducer } from "react";
import { useNavigate, useParams, useLocation } from "react-router-dom";
import styled from "@emotion/styled";
import { Helmet } from "react-helmet-async";
import { BugOpened } from "@pages/components/BugOpened/BugOpened";
import { BugKanban } from "@pages/components/BugKanban/BugKanban";
import { BugList } from "@pages/components/BugList/BugList";
import { useQuery } from "@apollo/client";
import { emptyItem } from "@/data/models";
import { setGlobal, getGlobal } from "@/hooks/functions";
import { ViewKanbanOutlined, Segment } from "@mui/icons-material";
import {
  FormControl,
  Divider as MuiDivider,
  Grid,
  Link,
  MenuItem,
  Typography as MuiTypography,
  Paper,
  Select,
  InputBase,
  IconButton as MuiIconButton,
} from "@mui/material";
import { spacing } from "@mui/system";
import { RELEASES_GQL } from "@data/gql_queries";
import { useAuth, hasAuthParams } from "react-oidc-context";
import { initBugs } from "@data/models";

const IconButton = styled(MuiIconButton)`
  svg {
    color: #6d6d6d;
  }
`;

const Typography = styled(MuiTypography)(spacing);

const FormControlStyled = styled(FormControl)`
  margin: 5px 0px 5px 10px;
`;

const PaperStyled = styled(Paper)`
  float: right;
  display: flex;
  align-items: center;
  border-radius: 8px;
  border: 1px solid #b5b5b5;
  // margin-top: -5px;
  margin: 2px 20px;
`;

const LinkStyled = styled(Link)`
  margin-left: 10px;
  margin-right: 10px;
  font-size: 13px;
  font-weight: 400;
  line-height: 1.5;
  &:hover {
    text-decoration: none;
    border-bottom: 1px solid rgba(0, 0, 0, 0.54);
    cursor: pointer;
  }
`;

const BootstrapInput = styled(InputBase)(({ theme }) => ({
  "label + &": {
    marginTop: theme.spacing(3),
  },
  "& .MuiInputBase-input": {
    borderRadius: 0,
    position: "relative",
    backgroundColor: theme.palette.background.paper,
    borderBottom: "1px solid #ced4da",
    fontSize: 13,
    textAlign: "center",
    padding: "0px",
    // transition: theme.transitions.create(["border-color"]),
    // Use the system font instead of the default Roboto font.
    // fontFamily: [
    //   "Inter",
    //   "-apple - system",
    //   "BlinkMacSystemFont",
    //   "Segoe UI",
    //   "Roboto",
    //   "Helvetica Neue",
    //   "Arial",
    //   "sans - serif",
    //   "Apple Color Emoji",
    //   "Segoe UI Emoji",
    //   "Segoe UI Symbol",
    // ].join(","),
  },
}));

const Divider = styled(MuiDivider)(spacing);

function BugsPage() {
  const { loading, error, data } = useQuery(RELEASES_GQL, {
    variables: { productId: 1, page: 1, size: 100 },
  });

  const [view, changeView] = useState(false);
  const params = useParams();
  const [version, setVersion] = useState("");
  const navigate = useNavigate();

  const auth = useAuth();

  const [open, setOpen] = useState(false);
  const location = useLocation();

  const updateBugs = (bugs, action) => {
    let cloneBugs = {};
    Object.assign(cloneBugs, bugs);
    if (action.delete) {
      [...action.newBugs].forEach((new_item) => {
        cloneBugs[action.state] = cloneBugs[action.state].filter((item) => {
          return item.id !== new_item.id;
        });
      });
    } else if (action.update) {
      // Удаление
      [...action.newBugs].forEach((new_item) => {
        cloneBugs[action.state] = cloneBugs[action.state].filter((item) => {
          return item.id !== new_item.id;
        });
      });
      // Добавление
      cloneBugs[action.new_state] = [
        ...cloneBugs[action.new_state],
        ...action.newBugs,
      ];
    } else {
      let newBugs = [...action.newBugs].filter((new_item) => {
        let res = cloneBugs[action.state].findIndex((exist_item) => {
          return exist_item.id === new_item.id;
        });
        return res === -1;
      });

      cloneBugs[action.state] = [...cloneBugs[action.state], ...newBugs];
    }
    return cloneBugs;
  };

  const [bugs, newBugs] = useReducer(updateBugs, initBugs());

  useEffect(() => {
    if (!loading && !error && data) {
      setVersion(() => {
        if (params.version === "lastversion") {
          const newArray = [...data.releases].sort((a, b) => {
            return a.id - b.id;
          });
          return newArray[0].name;
        } else {
          return params.version;
        }
      });
    }
  }, [loading, error, data, params]);

  useEffect(() => {
    setGlobal({ location: `Ошибки в версии конфигурации ${version}` });
  }, []);

  const ChangeVersion = (event) => {
    setVersion(event.target.value);
    navigate("/" + event.target.value);
  };

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    navigate(location.pathname);
  };

  // switch (auth.activeNavigator) {
  //   case "signinSilent":
  //     console.log("signinSilent");
  //     return <div>Signing you in...</div>;
  //   case "signoutRedirect":
  //     console.log("signoutRedirect");
  //     return <div>Signing you out...</div>;
  // }

  // if (auth.isLoading) {
  //   return <div>Проверка авторизации...</div>;
  // }

  // if (auth.error) {
  //   console.error(auth.error);
  //   return <div>Ошибка аутентификации...</div>;
  // }

  // if (!auth.isAuthenticated) {
  //   return <div>Авторизация...</div>;
  // }

  return (
    <React.Fragment>
      <Helmet title="Ошибки в релизе" />
      <Grid
        container
        sx={{
          justifyContent: "space-between",
          "@media (max-width:960px)": {
            width: "100%",
            flexDirection: "column",
            justifyContent: "end",
          },
        }}
      >
        <Grid item sx={{ "@media (max-width:960px)": { display: "none" } }}>
          <Typography variant="h3" gutterBottom display="inline">
            Ошибки в версии конфигурации {version}
          </Typography>
        </Grid>
        <Grid item>
          <PaperStyled>
            {auth.isAuthenticated && (
              <LinkStyled color="inherit" onClick={handleClickOpen}>
                {"Создать"}
              </LinkStyled>
            )}
            <BugOpened
              open={open}
              onClose={handleClose}
              item={emptyItem}
              newBugs={newBugs}
            />
            <FormControlStyled
              variant="standard"
              sx={{ m: 1, minWidth: 80 }}
              size="small"
            >
              <Select
                defaultValue=""
                value={version}
                inputProps={{
                  name: "Релиз",
                  id: "demo-select-small",
                }}
                onChange={ChangeVersion}
                input={<BootstrapInput />}
              >
                {!error &&
                  !loading &&
                  [...data.releases]
                    .sort((a, b) => {
                      return a.id - b.id;
                    })
                    .map((release, index) => (
                      <MenuItem key={index} value={release.name}>
                        {release.name}
                      </MenuItem>
                    ))}
              </Select>
            </FormControlStyled>

            <LinkStyled
              sx={{ "@media (max-width:559px)": { display: "none" } }}
              color="inherit"
              onClick={() => {
                //client.clearStore();
                changeView(false);
              }}
            >
              {"Канбан"}
            </LinkStyled>
            <IconButton
              color="inherit"
              sx={{ "@media (min-width:560px)": { display: "none" } }}
              aria-label="Канбан"
              onClick={() => {
                //client.clearStore();
                changeView(false);
              }}
              size="small"
            >
              <ViewKanbanOutlined />
            </IconButton>
            <LinkStyled
              sx={{ "@media (max-width:559px)": { display: "none" } }}
              color="inherit"
              onClick={() => {
                //client.clearStore();
                changeView(true);
              }}
            >
              {"Список"}
            </LinkStyled>
            <IconButton
              color="inherit"
              sx={{ "@media (min-width:560px)": { display: "none" } }}
              aria-label="Список"
              onClick={() => {
                //client.clearStore();
                changeView(true);
              }}
              size="small"
            >
              <Segment />
            </IconButton>
          </PaperStyled>
        </Grid>
      </Grid>
      <Divider my={4} />
      {view ? (
        <BugList version={version} view={view} bugs={bugs} newBugs={newBugs} />
      ) : (
        <BugKanban
          version={version}
          view={view}
          bugs={bugs}
          newBugs={newBugs}
        />
      )}
    </React.Fragment>
  );
}

export default BugsPage;
