import React from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { BugOpened } from "@pages/components/BugOpened/BugOpened";
import styled from "@emotion/styled";
import { spacing } from "@mui/system";
import {
  Card as MuiCard,
  CardContent as MuiCardContent,
  Typography as MuiTypography,
} from "@mui/material";

const CardContent = styled(MuiCardContent)`
  &:last-child {
    padding-bottom: ${(props) => props.theme.spacing(4)};
  }
`;

const Typography = styled(MuiTypography)(spacing);

const Card = styled(MuiCard)(spacing);

const TaskWrapper = styled(Card)`
  border: 1px solid ${(props) => props.theme.palette.grey[300]};
  margin-bottom: ${(props) => props.theme.spacing(4)};
  cursor: grab;

  &:hover {
    background: ${(props) => props.theme.palette.background.default};
  }
`;

const TaskWrapperContent = styled(CardContent)`
  position: relative;

  &:last-child {
    padding-bottom: ${(props) => props.theme.spacing(4)};
  }
`;

const TaskTitle = styled(Typography)`
  font-weight: 600;
  font-size: 15px;
  margin-right: ${(props) => props.theme.spacing(10)};
`;

export const KanbanCard = ({ item, newBugs }) => {
  const [open, setOpen] = React.useState(false);
  const handleClickOpen = () => {
    setOpen(true);
  };
  const navigate = useNavigate();
  const location = useLocation();
  const handleClose = () => {
    setOpen(false);
    navigate(location.pathname);
  };
  return (
    <TaskWrapper mb={4}>
      <TaskWrapperContent
        sx={{ "&:hover": { cursor: "pointer" } }}
        onClick={handleClickOpen}
      >
        <TaskTitle variant="body1" gutterBottom>
          {item.name}
        </TaskTitle>
        {item.description}
      </TaskWrapperContent>
      <BugOpened
        open={open}
        onClose={handleClose}
        item={item}
        newBugs={newBugs}
      />
    </TaskWrapper>
  );
};
