import React, { useEffect, useState } from "react";

import { TextField, Typography, Card, Divider } from "@mui/material";
import { DragDropFile } from "@pages/components/BugOpened/DragDropComponent";

export const BugWorkaround = (props) => {
  const [text, setText] = useState(props.workaround?.comment);
  useEffect(() => {
    setText(props.workaround?.comment);
  }, [props.workaround?.comment]);
  useEffect(() => {
    if (props.loading) {
      setText("Загрузка..");
    } else {
      setText(props.workaround?.comment);
    }
  }, [props.loading]);
  return (
    <Card sx={{ marginTop: 4 }} variant="outlined">
      <Typography variant="h6" sx={{ paddingTop: 3, px: 4, paddingBottom: 1 }}>
        Способ обхода
      </Typography>
      <Divider />
      <TextField
        InputProps={{
          readOnly: props.isAdmin ? false : true,
        }}
        sx={{
          "& fieldset": { border: "none" },
        }}
        fullWidth
        id="bug-workaround"
        multiline
        rows={2}
        defaultValue={text}
        onChange={(e) => {
          setText(e.target.value);
          props.updateField("workaround", e.target.value);
        }}
      />
      <DragDropFile
        arrOfIdFiles={props.workaround?.files || []}
        isAdmin={props.isAdmin}
        updateField={props.updateField}
        setMessageAlert={props.setMessageAlert}
        setTypeAlert={props.setTypeAlert}
        variant="workaround"
        variantText={text}
      />
    </Card>
  );
};
